import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PLAN_COSTS_ANNUAL } from './plans'

import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function PricingFAQSection () {
  const isMobile = useMobileDetect()
  const [searchParams] = useSearchParams()
  const openTab = searchParams.get('faq')
  const [expanded, setExpanded] = useState(Number(openTab) ?? 0)
  const ref = useRef(null)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (openTab && ref.current) {
      // Scroll to the FAQ section smoothly
      ref.current.scrollIntoView({ behavior: 'smooth' })

      // Open the corresponding accordion panel
      setExpanded(Number(openTab))
    }
  }, [openTab])

  return (
    <Container ref={ref} id='faq' className={clsx([{ mobile: isMobile }])}>
      <h2>Frequently asked questions</h2>
      <div className='faq'>
        {FAQ.map((accordion, i) => (
          <Accordion expanded={expanded === i} onChange={handleChange(i)} key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={i}
              id={i}
            >
              <p className='name'>{accordion.name}</p>
            </AccordionSummary>
            <AccordionDetails>
              {accordion.description}
            </AccordionDetails>
          </Accordion>
        ))}

      </div>
    </Container>
  )
}

const FAQ = [
  {
    name: 'Subscription Tiers',
    description:
  <>
    PB Vision offers a 1-month free trial of the Premium plan, which comes with three Credits to get started. After that, you&apos;ll have the option to choose the best plan for you!
    <br />
    <ul>
      <li><strong>Starter</strong>: ${Math.round(PLAN_COSTS_ANNUAL.starter / 12).toFixed(0)} - billed at ${PLAN_COSTS_ANNUAL.starter} annually
        Ideal for casual players looking to review their performance with essential stats. Includes 60 Credits granted at the start of each billing year.
      </li>
      <li><strong>Premium</strong>: ${Math.round(PLAN_COSTS_ANNUAL.premium / 12).toFixed(0)} - billed at ${PLAN_COSTS_ANNUAL.premium} annually
        Great for players wanting deeper insights and access to premium features such as shot explorer. Includes 180 Credits granted at the start of each billing year.
      </li>
      <li><strong>Professional</strong>: Tailored for teams, coaches, and facilities.
        Custom pricing based on your needs. Contact us for more details!
      </li>
    </ul>
  </>
  },
  {
    name: 'Will Uploading Videos Use My Credits?',
    description:
  <>
    Yes, a credit lets you process one pickleball game (a typical game is 12-15 minutes long but we know it can vary) and receive a full breakdown of all the stats and insights our AI engine generates. Whether it’s rally lengths, shot types, or player-specific performance, our video processing will give you a treasure trove of actionable data.
    <br />
    <ul>
      <li><strong>1 Credit  = 1 game processed</strong></li>
    </ul>
    As a subscriber, you&apos;ll automatically receive a set number of credits based on your plan, but if you need more, simply head to your account page to top up!
  </>
  },
  { name: 'Will My Credits Roll-Over To The Next Month?', description: <>Yes, credits rollover annually and don&apos;t expire.</> },
  {
    name: 'Where Can I Find How Many Credits I Have?',
    description:
  <>
    You can find the remaining credits for your account in your profile.
    <br />
    <ul>
      <li><strong>Web:</strong> <br />
        <ul>
          <li>Select your player profile in the top right hand corner of the screen</li>
          <li>Your remaining credits will appear at the bottom of the screen</li>
        </ul>
      </li>
      <li><strong>Android/iOS:</strong> <br />
        <ul>
          <li>On the navigation bar, select <strong>Profile</strong></li>
          <li>Your remaining credits will appear at the bottom of the screen</li>
        </ul>
      </li>
    </ul>
  </>
  },
  {
    name: 'If I Delete A Video From My Library, Will I Get A Credit Back?',
    description:
  <>Removing a video from your library will not give you a credit. Credits are received through subscriptions and are used only when uploading videos. If you run into any issues with a video, before attempting to upload again, please reach out to us at <a href='mailto:support@pb.vision'>support@pb.vision</a>.</>
  },
  { name: 'What Happens If I Cancel My Subscription?', description: <>If you cancel, your account will stay active until the end of your billing period. Your remaining Credits, will still be associated with your account, but in order to use them, you will need to reactivate a subscription. If you don’t re-subscribe, your data will be kept for 30 days, giving you time to come back.</> },
  { name: 'I Am Getting A Message Saying Free Credit Limit Exceeded', description: <>Currently, all accounts were credited with 10 Credits to be able to process games during our transition to paid subscription models. If you have already used all 10 credits, this message will appear. Until the subscriptions begin in October, please email <a href='mailto:support@pb.vision'>support@pb.vision</a> if you need extra time.</> },
  { name: 'Do You Have An Ambassador Program?', description: <>We are actively working on finalizing the details of our ambassador program. Keep an eye in Discord for further information as we will be posting updates in our Announcements channels.</> }
]

const Container = styled('div')({
  ...column,
  alignItems: 'center',
  gap: '40px',
  flexWrap: 'wrap',
  textAlign: 'center',
  '& h2': {
    fontSize: '40px',
    fontWeight: 700
  },
  '& .faq': {
    ...row,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'start',
    gap: 16,
    '& .MuiPaper-root': {
      fontSize: 16,
      width: 'calc(50% - 8px)',
      fontWeight: 500,
      lineHeight: '24px',
      backgroundColor: COLORS['neutral-100'],
      borderRadius: 0,
      boxShadow: 'none',
      border: `1px solid ${COLORS['neutral-300']}`,
      height: 'fit-content',
      '&.Mui-expanded': {
        backgroundColor: COLORS.white,
        margin: 0,
        minHeight: 48
      },
      '&:before': {
        display: 'none'
      },
      '& .MuiAccordionDetails-root': {
        '& ul': {
          marginBlock: 10,
          marginLeft: 30
        }
      }
    }
  },
  '&.mobile': {
    '& h2': {
      fontSize: '24px'
    },
    '& .faq': {
      ...column,
      gap: 16,
      '& .MuiPaper-root': {
        fontSize: 16,
        width: '100%'
      }
    }
  }
})
