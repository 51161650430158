import { useDispatch, useSelector } from 'react-redux'

import { useIsLoggedIn, useMyMonetizationData } from '@/store/auth'
import { showBanner } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'

export function useCanUploadVideo () {
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const myMonetizationData = useMyMonetizationData()
  const alreadyUploadedAsAnonymous = useSelector(x => x.anonymous.uploadedVids)

  function canUploadNewVideo () {
    if (isLoggedIn) {
      // If user have credits left we allow them to upload video
      if (myMonetizationData.creditsLeft) {
        return true
      }
      return false
    } else {
      // anonymous users can only upload one video
      return alreadyUploadedAsAnonymous.length < 1
    }
  }

  function alertUserForForbiddenUpload () {
    if (isLoggedIn) {
      if (myMonetizationData.currentSubscription) {
        dispatch(showBanner(BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT))
      } else {
        dispatch(showBanner(BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT))
      }
    } else {
      dispatch(showBanner(BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT))
    }
  }

  return { canUploadNewVideo, alertUserForForbiddenUpload }
}
