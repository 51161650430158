import { styled } from '@mui/material/styles'
import { useState } from 'react'

import { TagPlayerPopup } from './tag-player-popup'

import { useMayEditVideo } from '@/store/video'
import COLORS from '@/utils/colors'

/**
 * TagPlayer container
 *
 * @returns {React.ReactElement}
 */
export const Container = styled('div')(({ truncate }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  '& .player-name': {
    fontSize: 16,
    width: '100% !important',
    fontWeight: 700,
    lineHeight: '133%',
    color: COLORS['neutral-800']
  },
  '& .truncate': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  // If truncate property exists, apply these CSS properties.
  overflow: truncate ? 'hidden' : 'visible',
  textOverflow: truncate ? 'ellipsis' : 'clip',
  whiteSpace: truncate ? 'nowrap' : 'normal'
}))

/**
 * TagPlayer component
 *
 * @exports
 * @param props {object} {vid, playerIdx, playerName, aiEngineVersion}
 * @returns {React.ReactElement}
 */
export function TagPlayer (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { vid, playerIdx, playerName, aiEngineVersion } = props
  const mayTag = useMayEditVideo(vid)
  const initialName = playerName || `Player ${playerIdx + 1}`

  const onContainerClick = (event) => {
    if (!mayTag) return
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => setAnchorEl(null)

  return (
    <>
      <Container
        truncate={props.className === 'truncate'}
        onClick={onContainerClick}
        sx={props.sx}
      >
        {props.children}
        <span className={`player-name ${props.className ? props.className : ''}`}>
          {initialName}
        </span>
      </Container>
      <TagPlayerPopup
        vid={vid}
        playerIdx={playerIdx}
        initialName={initialName}
        playerName={playerName}
        aiEngineVersion={aiEngineVersion}
        onClose={onClose}
        anchorEl={anchorEl}
      />
    </>
  )
}
