import { useState } from 'react'

import { isInMobileAppWebview, promptPurchaseOnNativeApp } from '@/utils/mobile-app-communication'

export function usePurchaseFlow () {
  const [skuToBuy, setSKUToBuy] = useState()

  function buySKU (sku) {
    if (isInMobileAppWebview()) {
      promptPurchaseOnNativeApp(sku)
    } else {
      setSKUToBuy(sku)
    }
  }
  return {
    clearSKUToBuy: () => setSKUToBuy(),
    skuToBuy,
    setNumCreditsToBuy: n => buySKU(`credit${n}`),
    subscribeToPlan: tier => buySKU(`annual_${tier}`)
  }
}
