import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import CreditCard from '@mui/icons-material/CreditCard'
import { Dialog, IconButton, styled } from '@mui/material'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PBV1Img from '@/assets/images/1pbv.png'
import PBV30Img from '@/assets/images/30pbv.png'
import PBV5Img from '@/assets/images/5pbv.png'
import { Button } from '@/components/button'
import { Divider } from '@/components/divider'
import { Spinner } from '@/components/spinner'
import { useIsBuyingEnabled } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import { CheckoutForm } from '@/pages/stripe'
import { useMyMonetizationData } from '@/store/auth'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

dayjs.extend(relativeTime)
dayjs.extend(isToday)

/*
const DUMMY_TRANSACTIONS = [
  { date: '2024-10-06T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-10-05T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-10-05T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-02-03T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-10-01T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-10-01T12:42:35+0000', kind: 'timed', vid: '123456' },
  { date: '2024-10-01T12:42:35+0000', kind: 'timed', vid: '123456' }
]
*/

export default function Credits () {
  const md = useMyMonetizationData()
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const isDesktop = useMobileDetect('desktop')
  const { clearSKUToBuy, skuToBuy, setNumCreditsToBuy, subscribeToPlan } = usePurchaseFlow()

  const isBuyingEnabled = useIsBuyingEnabled()

  function onCloseDialog () {
    setIsDialogOpened(false)
    clearSKUToBuy()
  }
  /*
  function getRelativeDate (date) {
    const dayjsDate = dayjs(date)

    if (dayjsDate.isToday()) {
      return 'today'
    }
    return dayjsDate.fromNow()
  }
  */

  return (
    <>
      <Container className={cls(!isDesktop ? 'mobile' : '')}>
        <h2>Credits</h2>
        <p className='body-sm'>A Credit lets you analyze one pickleball game. This includes a detailed breakdown of all the stats our AI engine can produce.</p>
        {/*
        <a className='body-sm' style={{ fontWeight: 600 }} href='#'>Learn more about Credits</a>
        */}
        <Divider style={{ marginBottom: 24 }} />
        {md.isLoading && <Spinner text='Loading...' />}
        {!md.isLoading && (
          <h3>Your balance is <CreditCard style={{ color: COLORS['primary-500'] }} /> {md.creditsLeft.toLocaleString()} Credits</h3>
        )}
        {isBuyingEnabled && (
          md.isSubscriber
            ? <Button className='green' onClick={() => setIsDialogOpened(true)}>Purchase Credits <CreditCard style={{ color: COLORS.white }} /></Button>
            : <Button className='green' LinkComponent={Link} to='/subscribe'>Subscribe to get more credits</Button>
        )}
        {/*
        <div className='transaction-table'>
          <p className='title-lg'>Transaction History</p>
          <table>
            <tr className='head'>
              <th className='date'>When</th>
              <th>Spend ID</th>
              <th>Kind</th>
              <th>Video ID</th>
            </tr>
            <div className='table-content'>
              {DUMMY_TRANSACTIONS.map((transaction, i) => (
                <tr key={i}>
                  <td className='date'>{dayjs(transaction.date).format('ddd MMM YY HH:mm')} <div className='from-now'>{getRelativeDate(transaction.date)}</div></td>
                  <td>{DUMMY_TRANSACTIONS.length - i}</td>
                  <td>{transaction.kind}</td>
                  <td><a href={`/video/${transaction.vid}`} target='_blank' rel='noreferrer'>{transaction.vid}</a></td>
                </tr>

              ))}
            </div>
          </table>
        </div>
        */}
      </Container>
      <Dialog open={isDialogOpened} onClose={onCloseDialog}>
        <PricingPlans>
          <div className='header'>
            <h3>Purchase Credits</h3>
            <IconButton className='close-button' onClick={onCloseDialog} title='Close'>
              <CloseIcon className='close-icon' />
            </IconButton>
          </div>
          {!skuToBuy && (
            <div style={{ ...column, gap: 16 }}>
              <div className='pricing-plans'>
                {PRICING_PLANS.map((plan, i) => (
                  <div className='pricing-plan' key={i} onClick={() => setNumCreditsToBuy(plan.creditCount)}>
                    <div className='prefix'>{plan.prefix}</div>
                    <div className='plan-name'>{plan.packName}</div>
                    <div className='discount'>{plan.discount}</div>
                    {plan.Image}
                    <div className='price'>{plan.price}</div>
                    <Button className='green'>Select</Button>
                  </div>
                ))}
              </div>
              {md.currentSubscription?.tier !== 'premium' && (
                <div className='save-with-premium'>
                  <h4>Save with Premium</h4>
                  <div>
                    <CheckCircleIcon style={{ color: COLORS['primary-500'] }} />
                    Includes <span>180 Credits</span>
                  </div>
                  <Button className='green' onClick={() => subscribeToPlan('premium')}>Upgrade to a <br /> Premium Subscription</Button>
                </div>

              )}
            </div>

          )}

          {skuToBuy && (
            <CheckoutForm productId={skuToBuy} />
          )}
        </PricingPlans>
      </Dialog>
    </>
  )
}

const PRICING_PLANS = [
  {
    packName: '1 Credit',
    discount: <div style={{ color: COLORS['neutral-400'] }}>No discount</div>,
    Image: <img src={PBV1Img} alt='1 Credit' />,
    price: '$9.99',
    creditCount: 1
  },
  {
    packName: '5-Pack',
    discount: <div style={{ color: COLORS.white, background: COLORS['neutral-400'] }}>45% off</div>,
    Image: <img src={PBV5Img} alt='5 Credit' />,
    price: '$29.99',
    creditCount: 5,
    prefix: 'MOST POPULAR'
  },
  {
    packName: '30-Pack',
    discount: <div style={{ color: COLORS.white, background: 'linear-gradient(94deg, #6EFF65 -24.74%, #13D208 101.31%)' }}>67% off</div>,
    Image: <img src={PBV30Img} alt='30 Credit' />,
    price: '$99.99',
    creditCount: 30,
    prefix: '⭐ BEST VALUE ⭐'
  }
]

const PricingPlans = styled('div')({
  ...column,
  padding: '16px 24px',
  minWidth: 500,
  gap: 16,
  backgroundColor: COLORS['neutral-100'],
  border: `1px solid ${COLORS['neutral-300']}`,
  '& .header': {
    ...row,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .pricing-plans': {
    ...row,
    gap: 16,
    '& .pricing-plan': {
      ...column,
      flex: 1,
      minWidth: 174,
      backgroundColor: COLORS['neutral-100'],
      border: `1px solid ${COLORS['neutral-400']}`,
      borderRadius: 8,
      cursor: 'pointer',
      '& .prefix': {
        ...column,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 10,
        fontWeight: 600
      },
      '& .plan-name': {
        ...column,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS['neutral-200'],
        fontSize: 20,
        fontWeight: 700
      },
      '& .price': {
        ...column,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS['neutral-200'],
        fontSize: 29,
        fontWeight: 700
      },
      '& .discount': {
        fontSize: 11,
        fontWeight: 600,
        '& div': {
          ...column,
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
      '& image': {
        width: '100%',
        height: 124
      },
      '& button': {
        margin: '8px 18px 16px'
      }

    }

  },
  '& .save-with-premium': {
    ...row,
    alignItems: 'center',
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: 8,
    padding: 8,
    justifyContent: 'space-between',
    '& > div': {
      ...row,
      alignItems: 'center',
      gap: 4,
      fontSize: 12,
      fontWeight: 600,
      '& span': {
        color: COLORS['primary-500']
      }
    }
  }
})

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  gap: '8px',
  '& h3': {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  '& .transaction-table': {
    width: '100%',
    marginTop: 24,
    '& table': {
      width: '100%',
      color: COLORS['neutral-800'],
      '& .table-content': {
        maxHeight: 400,
        overflow: 'auto',
        '&::-webkit-scrollbar ': {
          display: 'none'
        }
      },
      '& tr': {
        ...row,
        border: `1px solid ${COLORS['neutral-300']}`,
        padding: '16px 16px',
        '&.head': {
          backgroundColor: COLORS['neutral-200']
        },
        '& td, th': {
          ...row,
          flex: 1,
          alignItems: 'center',
          '&.date': {
            flex: 3
          }
        }
      },
      '& .date .from-now': {
        backgroundColor: COLORS['neutral-200'],
        borderRadius: 20,
        padding: '5px 10px',
        marginLeft: 5,
        fontSize: '12px'
      }
    }
  },
  '&.mobile': {
    '& .transaction-table': {
      '& table': {
        '& tr': {
          padding: '8px 8px',
          '& td, th': {
            flexWrap: 'wrap',
            textAlign: 'start',
            alignItems: 'flex-start'
          }
        },
        '& .date .from-now': {
          marginLeft: 0,
          padding: '5px'
        }
      }
    }
  }

})
