import styled from '@emotion/styled'
import clsx from 'clsx'

import COLORS from '../../utils/colors'

import PricingAmbassadorProgramSection from './sections/ambassador-program'
import PricingFAQSection from './sections/faq'
import PricingPlansSection from './sections/plans'

import { Divider } from '@/components/divider'
import useMobileDetect from '@/hooks/use-mobile-detect'

export default function PricingPage () {
  const isMobile = useMobileDetect()

  return (
    <Container className={clsx([{ mobile: isMobile }])}>
      <div>
        <h1 className='title'>
          Everything you need to level up your game
        </h1>
        <h2 className='subtitle'>
          Try Premium free for 1 month. <strong>No credit card required</strong>
        </h2>
      </div>
      <PricingPlansSection />
      <Divider className='full-width' />
      <PricingAmbassadorProgramSection />
      <Divider className='full-width' />
      <PricingFAQSection />
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100% !important',
  maxWidth: '1142px !important',
  paddingTop: '120px',
  paddingInline: '15px',
  paddingBottom: '50px',
  gap: 80,
  '& > div': {
    width: '100%'
  },
  '& h1': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '51.84px',
    letterSpacing: '-2.619px'
  },
  '& h2': {
    marginTop: '16px',
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '25.92px'
  },
  '&.mobile': {
    paddingTop: '30px',
    width: '100% !important',
    gap: 40,
    '& h1': {
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: 0
    },
    '& h2': {
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: 0
    }
  }
})
