import { Box, styled } from '@mui/material'
import React from 'react'

import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import { column } from '@/utils/flexGrid'

export default function Ambassador () {
  const isDesktop = useMobileDetect('desktop')
  return (
    <Container className={cls(!isDesktop ? 'mobile' : '')}>
      <h2>Ambassador Program</h2>
      <Box sx={{ color: 'black', fontStyle: 'italic' }}>Coming soon!</Box>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  gap: '8px'

})
