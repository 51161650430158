import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@/components/button'
import { Spinner } from '@/components/spinner'
import { UploadButton } from '@/components/upload/UploadButton'
import Container from '@/pages/stripe/container'
import { useLoggedInUserCredentials, useMyMonetizationData } from '@/store/auth'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

// created outside of any component to avoid recreating it
async function loadStripeWrapper () {
  if (isInMobileAppWebview()) {
    return
  }
  const { loadStripe } = await import('@stripe/stripe-js')
  return loadStripe(import.meta.env.STRIPE_PUBLISHER_KEY)
}
export const stripePromise = loadStripeWrapper()

export function CheckoutForm ({ productId }) {
  const { token, uid } = useLoggedInUserCredentials()
  const fetchClientSecret = useCallback(() => {
    const body = { productId }
    if (window.location.hostname.startsWith('localhost')) {
      body.toLocalhost = true // redirect to localhost web app when checkout is done
    }
    return window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-uid': uid,
        'x-token': token
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => data.clientSecret)
  }, [productId, token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout component for mobile app'
  }
  if (!token) {
    return <Spinner text='Loading...' />
  }

  const options = { fetchClientSecret }
  return (
    <div id='checkout'>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

function StripeCheckoutReturnPage () {
  const { token, uid } = useLoggedInUserCredentials()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('session_id')

    if (!token || isInMobileAppWebview()) {
      return
    }

    window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-uid': uid,
        'x-token': token
      },
      body: JSON.stringify({ sessionId })
    })
      .then(res => res.json())
      .then(data => {
        setStatus(data.status)
      })
  }, [token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout return component for mobile app'
  }

  if (status === null) {
    return <Spinner text='Loading...' />
  }

  return (
    <Container>
      {status === 'complete'
        ? (
          <PurchaseSuccessful />
          )
        : (
          <PurchaseUnsuccessful />
          )}
    </Container>
  )
}

function PurchaseSuccessful () {
  return (
    <>
      <CheckCircleIcon className='icon check' />
      <div className='yellow-box'>Your purchase will be credited to your account within the next few seconds. Please refresh the page to see your new purchase reflected in the app.</div>
      <h2>Your purchase was successful!</h2>
      <div className='title'>You will be sent an email confirmation.</div>
      <div className='buttons'>
        <Button
          className='gray'
          variant='outlined'
          LinkComponent={Link}
          to='/library'
        >
          View your library
        </Button>
        <UploadButton className='green upload-btn' label='Upload Video' />
      </div>
    </>
  )
}

function PurchaseUnsuccessful () {
  const { isSubscriber } = useMyMonetizationData()

  return (
    <>
      <CancelIcon className='icon cancel' />
      <h2>Your purchase was not completed.</h2>
      <div className='title'>You have not been charged.</div>
      <Button
        className='green'
        variant='outlined'
        LinkComponent={Link}
        to={isSubscriber ? '/settings/credits' : '/subscribe'}
      >
        Start Over
      </Button>
    </>
  )
}

export default StripeCheckoutReturnPage
