import { AppBar, IconButton, Toolbar } from '@mui/material'
import { Link, useLocation, useMatch } from 'react-router-dom'

import GetOnMobile from '../get-on-mobile/get-on-mobile'

import AvatarIcon from '@/assets/avatar.svg?react'
import HelpOutlineIcon from '@/assets/help-outline.svg?react'
import Logo from '@/assets/pb-vis-horiz-logo.svg?react'
import { useFaviconBadge } from '@/components/navbar/use-favicon-badge'
import { UploadsContainer } from '@/components/upload'
import { UploadButton, AnonymousUploadButton } from '@/components/upload/UploadButton'
import { useDeviceOS } from '@/hooks/use-device-os'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { useIsLoggedIn } from '@/store/auth'
import { useUnseenVIDs } from '@/store/library'
import { useHasAnonymouslyUploadedVideoPending } from '@/store/uploads'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

const appBarStyle = {
  backgroundColor: COLORS['neutral-100'],
  borderBottom: `1px solid ${COLORS['neutral-300']}`,
  // Height includes border so it's actually 48 (47 + 1)
  minHeight: '47px !important',
  padding: '0 32px 0 20px',
  zIndex: 1000,
  position: 'relative',
  '.MuiToolbar-root': {
    minHeight: '47px !important'
  },
  '.grow': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '16px',
    justifyContent: 'flex-end',
    '& > svg': {
      marginLeft: '24px'
    },
    '.help': {
      padding: 0,
      backgroundColor: 'unset'
    },
    a: {
      lineHeight: 0
    }
  },
  '& .logo-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 130,
    marginLeft: '24px'
  },
  '& .video-lib': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: COLORS['neutral-200'],
    color: COLORS['neutral-700'],
    fontSize: 14,
    height: '47px',
    fontWeight: 600,
    marginLeft: '24px',
    marginRight: 'auto',
    minWidth: 130,
    textDecoration: 'none',
    '&.pbv-logo': {
      backgroundColor: 'unset'
    },
    '& .unseen-counter': {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: '3px',
      minWidth: '17px',
      aspectRatio: 1,
      lineHeight: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'red',
      color: 'white',
      fontSize: '14px',
      fontWeight: '500',
      borderRadius: '100%'
    }
  },
  '& .pb-vision': {
    width: 118
  },
  '& .logo': {
    maxWidth: 232
  },
  '& .sign-in, .recording-tips, .blog': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '166%',
    color: COLORS['text-tertiary'],
    padding: '0 12px',
    textDecoration: 'none'
  },
  '& .sign-in': {
    color: COLORS['primary-500']
  },
  '& .upload-btn': {
    fontSize: 12
  },

  '&.sticky': {
    position: 'sticky',
    top: 0,
    zIndex: 1000
  },
  // Mobile styles
  '&.mobile': {
    padding: '0 15px 0 15px',
    '& .logo': {
      maxWidth: 110,
      marginRight: '10px'
    },
    '.grow': {
      gap: '10px'
    },
    '& .video-lib': {
      backgroundColor: 'unset',
      marginLeft: '0'
    },
    '& .logo-container': {
      marginLeft: '0'
    }
  }
}

function AnonymousUploadToolbar ({ isDesktop }) {
  const isLoggedIn = useIsLoggedIn()
  const hasUnclaimedAnonymousUpload = useHasAnonymouslyUploadedVideoPending()
  const logoLink = (isInMobileAppWebview() || isLoggedIn || hasUnclaimedAnonymousUpload) ? '/library' : '/'

  return (
    <Toolbar disableGutters>
      <Link to={logoLink} className='video-lib pbv-logo'>
        {!isDesktop ? <Logo className='logo' /> : <Logo className='pb-vision' />}
      </Link>
    </Toolbar>
  )
}

function NavToolbar (props) {
  const { isDesktop } = props
  const isMobile = useMobileDetect()
  const { deviceOS } = useDeviceOS()
  const { toggleZendeskWidget } = useZendesk()
  const isLoggedIn = useIsLoggedIn()
  const location = useLocation()
  const isOnSettingsPage = location.pathname.startsWith('/settings/')
  const hasUnclaimedAnonymousUpload = useHasAnonymouslyUploadedVideoPending()
  const unseenVIDsResult = useUnseenVIDs()
  const unseenCount = unseenVIDsResult.unseenVIDs.size
  useFaviconBadge({ unseenCount, isReady: unseenVIDsResult.isReady })

  const logoLink = (isInMobileAppWebview() || isLoggedIn || hasUnclaimedAnonymousUpload) ? '/library' : '/'
  const UploadBtn = isLoggedIn ? UploadButton : AnonymousUploadButton

  return (
    <Toolbar disableGutters>
      <Link to={logoLink} className='video-lib pbv-logo'>
        {!isDesktop
          ? (
            <>
              <Logo className='logo' />
              {unseenVIDsResult.isReady && unseenCount > 0 && <div className='unseen-counter'>{unseenCount}</div>}
            </>
            )
          : (
            <Logo className='pb-vision' />
            )}
      </Link>

      {isLoggedIn && isDesktop && location.pathname !== '/' && (
        <Link to='/library' className='video-lib'>
          Video Library
          {unseenVIDsResult.isReady && unseenCount > 0 && <div className='unseen-counter'>{unseenCount}</div>}
        </Link>
      )}
      <div className='grow'>
        {!isLoggedIn && location.pathname !== '/login' && (
          <Link to='/login' className='sign-in'>
            Sign In
          </Link>
        )}
        {!isOnSettingsPage && !isMobile && <UploadBtn className='green upload-btn' label={isLoggedIn ? 'Upload Video' : 'Get Started'} />}
        {isMobile && deviceOS !== 'other' && <GetOnMobile style={{ height: '40px', width: '120px', borderRadius: '4px' }} />}
        {isLoggedIn && location.pathname !== '/' && (
          <IconButton className='help' onClick={toggleZendeskWidget}>
            <HelpOutlineIcon />
          </IconButton>
        )}
        {isLoggedIn && (
          <Link to='/settings/main'>
            <AvatarIcon />
          </Link>
        )}
      </div>
    </Toolbar>
  )
}

/**
 * BaseNavbar component
 *
 * @exports
 * @returns {React.ReactElement}
 */
export function BaseNavbar () {
  const location = useLocation()
  const matchUpload = useMatch('/anonymous-upload')
  const BaseToolbar = matchUpload ? AnonymousUploadToolbar : NavToolbar
  const Uploads = matchUpload ? null : <UploadsContainer />
  const isDesktop = useMobileDetect('desktop')

  return (
    <AppBar elevation={0} position='static' sx={appBarStyle} className={cls(!isDesktop && 'mobile', location.pathname === '/' && 'sticky')}>
      <BaseToolbar isDesktop={isDesktop} />
      {Uploads}
    </AppBar>
  )
}
