import SettingsMobileMain from './mobile/main'
import { Account } from './tabs/account'
import Ambassador from './tabs/ambassador'
import Credits from './tabs/credits'

import Page from './index'

export default [
  {
    path: '/settings/*',
    element: <Page />,
    children: [
      {
        path: 'choose',
        element: <SettingsMobileMain />
      },
      {
        path: 'main',
        element: <Account />
      },
      {
        path: 'credits',
        element: <Credits />
      },
      {
        path: 'ambassador',
        element: <Ambassador />
      }
    ]
  }
]
