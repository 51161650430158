import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { CountdownRemaining } from '../CountdownRemaining'

import { UploadButton } from './UploadButton'

import CloudIcon from '@/assets/cloud.svg?react'
import ErrorIcon from '@/assets/error-outline.svg?react'
import lottieCloud from '@/assets/lottie/cloud.json'
import { TextButton } from '@/components/button/text-button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useDialog } from '@/store/providers/dialog-provider'
import { clearEndedUpload, clearAllEndedUploads, useCancelUpload, useUploads } from '@/store/uploads'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { humanFileSize } from '@/utils/file'
import { column, row } from '@/utils/flexGrid'
import transparentPixel from '@/utils/transparent-pixel'

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  minWidth: 430,
  maxWidth: 430,
  maxHeight: 'calc(100vh - 100px)',
  boxShadow: '3px 9px 14px 0px rgba(0, 0, 0, 0.25)',
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: '4px 0 0 0',
  position: 'fixed',
  bottom: 5,
  overflow: 'hidden',
  right: 50,
  lineHeight: 1,
  zIndex: 10000,

  // Collapsed img
  '& > .status-img': {
    position: 'fixed',
    marginTop: 10,
    marginLeft: 37,
    color: '#fff',
    '& svg': {
      height: 53,
      width: 52
    }
  },
  '& .file-list': {
    ...column,
    flexGrow: 1,
    margin: 16,
    overflow: 'hidden',
    '& .files': {
      ...column,
      flexGrow: 1,
      overflow: 'auto'
    },

    '& .title': {
      fontSize: 20,
      fontWeight: 600,
      color: COLORS['neutral-700'],
      paddingBottom: '10px',
      flexGrow: 1,
      ...row,

      '& .toggle-button': {
        marginRight: 0,
        '&:not(.close)': {
          marginLeft: 'auto'
        },
        '& svg': {
          maxHeight: 40,
          maxWidth: 40,
          fontSize: 40
        }
      }
    }
  },
  '& .file': {
    ...row,
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: 4,
    marginTop: 16,
    overflow: 'hidden',
    position: 'relative',
    minHeight: 86,

    '& > .column': {
      overflow: 'hidden'
    },
    '& .status-img': {
      position: 'absolute',
      left: 38,
      width: 52,
      height: 53,
      top: '50%',
      // opacity: 0.5,
      transform: 'translateY(-50%)',
      color: '#fff',
      '&.pending': {
        opacity: 0.5
      },
      '& svg': {
        height: '100%',
        width: '100%'
      }
    },
    '& .MuiCardMedia-root': {
      height: 84
    },
    '&.queued .MuiCardMedia-root': {
      height: 71
    }
  },
  '& .MuiCardMedia-root': {
    width: 126,
    minWidth: 126,
    '&.small': {
      height: 71
    }
  },
  '& .MuiCardContent-root': {
    flex: '1 0 auto',
    padding: 8,
    width: '100%',

    '&:last-child': {
      paddingBottom: 8
    },
    '& > .row': {
      alignItems: 'center',
      gap: 8
    },
    '& svg': {
      fontSize: 20
    },
    '& .cancel': {
      // Cancel - button
      '& svg': {
        color: COLORS['neutral-400']
      }
    }
  },
  '& .subtitle': {
    fontSize: 15,
    fontWeight: 700,
    marginTop: 7,
    '&.small': {
      marginBottom: 7,
      fontSize: 12
    }
  },
  '& .uploading-all': {
    // overflow: 'hidden',
    color: COLORS['neutral-800'],
    // textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 600
  },
  '& .collapsed-title': {
    whiteSpace: 'nowrap'
  },
  '& .details': {
    overflow: 'hidden',
    color: COLORS['neutral-800'],
    textOverflow: 'ellipsis',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '133%',
    minHeight: '1.33em',

    '& a': {
      color: COLORS['primary-500'],
      textDecoration: 'none'
    }
  },
  '& .name': {
    color: COLORS['neutral-800'],
    fontSize: 14,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: 5
  },
  // Failed name field should inherit root color (red)
  '&.failed .name': {
    color: 'inherit'
  },
  // Make the failed card reddish
  '& .failed .MuiCardContent-root': {
    backgroundColor: COLORS['danger-050']
  },
  '& .toggle-button': {
    alignSelf: 'center',
    color: COLORS['neutral-400'],
    '&:last-of-type': {
      marginRight: 14
    },
    '&.close svg': {
      fontSize: '24px !important'
    },
    '& svg': {
      maxHeight: 40,
      maxWidth: 40,
      fontSize: 40
    }
  },
  '& button.btn-green': {
    backgroundColor: COLORS['primary-100'],
    marginTop: 16,
    padding: '10px 0',
    fontSize: 16,
    fontWeight: 600,

    '& em': {
      color: COLORS['primary-700'],
      order: 2
    },

    '& svg': {
      color: COLORS['primary-500'],
      fontSize: 28,
      marginRight: 12,
      order: 1
    }
  },
  '& .progress': {
    height: 6,
    borderRadius: 6,
    flexGrow: 1,
    '&.empty': {
      border: `1px solid ${COLORS['neutral-400']}`
    }
  },
  '& .queued .progress': {
    marginTop: 7
  },
  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .rotate180': {
    transform: 'rotate(180deg)'
  },
  '& .hidden': {
    display: 'none'
  },
  '& .empty-icon': {
    height: 20,
    width: 20
  },
  '& .error': {
    color: COLORS['danger-500']
  },
  '& .warning': {
    color: COLORS['warning-500']
  },
  '& .succeeded': {
    color: COLORS['primary-500']
  },
  '& .failed': {
    color: COLORS['neutral-400']
  },
  '&.mobile': {
    width: '100%',
    minWidth: 'unset',
    right: 'unset',
    bottom: 0,
    '& .collapsed-card-container': {
      overflow: 'hidden',
      '& .row': {
        flexWrap: 'wrap'
      }
    },
    '& .toggle-button': {
      margin: '0'
    }
  }
}))

const statusIcons = {
  pending: <CloudIcon />,
  uploading: <Lottie animationData={lottieCloud} style={{ width: 85, height: 85, marginTop: -15, marginLeft: -15 }} loop play />,
  failed: <ErrorIcon />,
  succeeded: <CheckCircleOutlineIcon />
}

export function UploadingDetails ({ upload, countdownPrefix = null }) {
  const cancelUpload = useCancelUpload()
  const completed = upload.status === 'succeeded'
  const showDialog = useDialog()
  return (
    <>
      {completed
        ? (
          <div className='details'>Upload complete!<br />
            We’ll email you when your report is ready
          </div>
          )
        : (
          <>
            <div className='details'>
              Uploaded {humanFileSize(upload.numBytesTotal)}
            </div>
            <div className='details'>
              {countdownPrefix}
              <CountdownRemaining epoch={upload.estimatedUploadFinishEpoch} />
            </div>
          </>
          )}
      <div className='row cancel'>
        <LinearProgress
          variant='determinate'
          className='progress'
          value={100 * upload.numBytesDone / upload.numBytesTotal}
        />
        {upload.status === 'uploading' && (
          <TextButton
            onClick={() => {
              showDialog({
                title: 'Are you sure?',
                disagreeText: 'Cancel Upload',
                agreeText: 'Continue Upload',
                onCancel: () => {
                  cancelUpload(upload.vid)
                }
              })
            }}
          >
            <CancelIcon />
          </TextButton>
        )}
        {upload.status !== 'uploading' && (
          <CheckCircleIcon className='success' />
        )}
      </div>
    </>
  )
}

const renderDetails = ({ dispatch, upload, status }) => {
  switch (status) {
    // upload statuses
    case 'pending':
      return (
        <>
          <div className='details'>Queued</div>
          <div className='progress empty' />
        </>
      )
    case 'uploading':
      return <UploadingDetails upload={upload} />
    case 'failed':
      return (
        <>
          <div className='details error'>{upload.error}</div>
          <div className='row' style={{ justifyContent: 'space-between' }}>
            <div className='details'>Please review our <Link to='https://www.pb.vision/video-tips#uploads' target='_blank'>video requirements</Link></div>
            <TextButton
              onClick={() => dispatch(clearEndedUpload({ vid: upload.vid }))}
            >
              <CancelIcon />
            </TextButton>
          </div>
        </>
      )
    default:
      return (
        <>
          <div className='details'>Upload successful</div>
          <div className='row'>
            <LinearProgress variant='determinate' className='grow progress' value={100} />
            <TextButton
              onClick={() => dispatch(clearEndedUpload({ vid: upload.vid }))}
            >
              <CancelIcon />
            </TextButton>
          </div>
        </>
      )
  }
}

/**
 * @param {UploadInfo} upload
 */
function Upload ({ upload }) {
  const dispatch = useDispatch()
  const status = upload.status
  const statusIcon = statusIcons[status]
  return (
    <div className={`file ${status}`}>
      <CardMedia component='img' image={upload.thumbnail ?? transparentPixel} alt='Live from space album cover' />
      <div className={cls('status-img', status)}>{statusIcon}</div>
      <div className='column grow'>
        <CardContent>
          <div className='name'>{upload.name}</div>
          {renderDetails({ dispatch, upload, status })}
        </CardContent>
      </div>
    </div>
  )
}

function getStats (uploads) {
  let numFailed = 0
  let numSucceeded = 0
  let numQueuedForUpload = 0
  let numUploadsInProgress = 0
  let numBytesUploaded = 0
  let numBytesToUpload = 0
  let estimatedEpochDone = 0
  let uploadIndex = null

  // One loop to get all the needed data
  uploads.forEach((upload, idx) => {
    if (upload.status === 'succeeded') {
      numSucceeded += 1
    } else if (upload.status === 'failed') {
      numFailed += 1
    } else if (upload.status === 'pending') {
      numQueuedForUpload += 1
    } else if (upload.status === 'uploading') {
      numUploadsInProgress += 1
      estimatedEpochDone = Math.max(upload.estimatedUploadFinishEpoch ?? 0, estimatedEpochDone)
      numBytesUploaded += upload.numBytesDone
      numBytesToUpload += upload.numBytesTotal
      if (uploadIndex === null) uploadIndex = idx
    }
  })
  const isCompleted = !numQueuedForUpload && !numUploadsInProgress
  const previewIndex = uploadIndex ?? 0
  const collapsedTitle = getCollapsedTitle()

  // Show progress with value when we have progress %
  const showDeterminate = numUploadsInProgress || isCompleted
  const determinateValue =
    showDeterminate && (isCompleted ? 100 : numBytesUploaded / numBytesToUpload * 100)

  function getCollapsedTitle () {
    const uploadsInProgressTitle = `Uploading ${numUploadsInProgress} of ${numUploadsInProgress + numQueuedForUpload || 1}`
    const uploadsFinishedTitle = numFailed ? `${numFailed} upload${numFailed > 1 ? 's' : ''} failed` : `${numSucceeded} successfully uploaded`
    const collapsedTitle = numUploadsInProgress ? uploadsInProgressTitle : uploadsFinishedTitle
    return collapsedTitle
  }

  let statusIcon
  if (numUploadsInProgress) {
    statusIcon = statusIcons.uploading
  } else if (numQueuedForUpload) {
    statusIcon = statusIcons.pending
  } else if (numFailed) {
    statusIcon = statusIcons.failed
  } else {
    statusIcon = statusIcons.succeeded
  }
  const allowClose = !numUploadsInProgress && !numQueuedForUpload && !numFailed
  return {
    allowClose,
    isCompleted,
    numUploadsInProgress,
    previewIndex,
    statusIcon,
    collapsedTitle,
    estimatedEpochDone,
    showDeterminate,
    determinateValue
  }
}

/**
 * @returns {React.ReactElement}
 */
export function UploadsContainer () {
  const dispatch = useDispatch()
  const isMobile = useMobileDetect()
  const uploads = useUploads()
  const [expanded, setExpanded] = useState(false)
  const open = Boolean(uploads?.length)
  const {
    allowClose,
    isCompleted,
    numUploadsInProgress,
    previewIndex,
    statusIcon,
    collapsedTitle,
    estimatedEpochDone,
    showDeterminate,
    determinateValue
  } = useMemo(() => {
    return getStats(uploads)
  }, [uploads])

  function close () {
    dispatch(clearAllEndedUploads())
  }

  return (
    <Fade in={open} timeout={350} unmountOnExit>
      <Card className={cls(expanded && 'expanded', isMobile && 'mobile')}>
        {expanded
          ? (
            <div className='file-list'>
              <div className='title'>
                <div className='column'>
                  Uploads
                  {numUploadsInProgress
                    ? (
                      <div className='subtitle warning'>Do not close the browser window</div>
                      )
                    : (
                      <div className='subtitle succeeded'>It&apos;s ok to close this window</div>
                      )}
                </div>
                <TextButton className='toggle-button' onClick={() => setExpanded(!expanded)}>
                  <KeyboardArrowUpIcon className='rotate180' />
                </TextButton>
                {allowClose && (
                  <TextButton className={cls('toggle-button', 'close')} onClick={close}>
                    <CloseIcon />
                  </TextButton>
                )}
              </div>
              <div className='files'>
                {uploads.map(upload => (
                  <Upload upload={upload} key={upload.vid} />
                ))}
              </div>
              <UploadButton className='btn-green' label='Upload another video' />
            </div>
            )
          : (
            <>
              <CardMedia
                component='img'
                className='small'
                image={uploads?.[previewIndex]?.thumbnail ?? transparentPixel}
              />
              {uploads?.[previewIndex] && <div className='status-img'>{statusIcon}</div>}
              <div className='column grow collapsed-card-container'>
                <CardContent>
                  <div className='row'>
                    <div className='uploading-all'>{collapsedTitle}</div>
                    <div className='details collapsed-title'>
                      {!isCompleted && <CountdownRemaining epoch={estimatedEpochDone} />}
                    </div>
                  </div>
                  {numUploadsInProgress
                    ? (
                      <div className='subtitle warning small'>Do not close the browser window</div>
                      )
                    : (
                      <div className='details' />
                      )}
                  {showDeterminate
                    ? (
                      <LinearProgress variant='determinate' value={determinateValue} />
                      )
                    : (
                      <LinearProgress variant='indeterminate' />
                      )}
                </CardContent>
              </div>
            </>
            )}
        {!expanded && (
          <>
            <TextButton className='toggle-button' onClick={() => setExpanded(!expanded)}>
              <KeyboardArrowUpIcon />
            </TextButton>
            {allowClose && (
              <TextButton className={cls('toggle-button', 'close')} onClick={close}>
                <CloseIcon />
              </TextButton>
            )}
          </>
        )}
      </Card>
    </Fade>
  )
}
