import { Divider, styled } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import ArrowLeftIcon from '@/assets/arrow-left.svg?react'
import BugIcon from '@/assets/bug-icon.svg?react'
import emojiUrl from '@/assets/emoji-grimacing.png'
import { Button } from '@/components/button'
import { Spinner } from '@/components/spinner'
import { useIsBuyingEnabled } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { useIsSubscriber, useMyMonetizationData, useCreditsAvailable } from '@/store/auth'
import { isDebugMode } from '@/utils'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function ProcessingFailed ({ video, workflow }) {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()

  function onBackToLibraryHandler () {
    navigate('/library')
  }

  function onContactUsHandler () {
    toggleZendeskWidget()
  }

  const IfErrorStateExist = () => {
    if (video.src.duration / 60 > 30) {
      return (
        <div className='info-container'>
          <strong className='info-title'>
            Sorry, your video was more than 30 minutes long. We don’t currently support videos of
            this length.
          </strong>
        </div>
      )
    }
    return (
      <div className='info-container'>
        <strong className='info-title'>
          Sorry, this video is taking longer than expected to analyze.
        </strong>
        {isDebugMode && (
          <pre style={{ background: 'white' }}>{JSON.stringify(workflow.error, null, 4)}</pre>
        )}
        <p>Our team has been notified.</p>
        <p>Don’t worry, if we can’t finish analyzing your video, we’ll refund your Credit.</p>
        <p>Your experience is top priority – rest assured, we’re on it!</p>
        <div className='buttons'>
          <Button className='icon-left green' onClick={() => onBackToLibraryHandler()}>
            <ArrowLeftIcon /> Back to Library
          </Button>
          <Button className='gray' onClick={() => onContactUsHandler()}>
            Contact Us <BugIcon />
          </Button>
        </div>
      </div>
    )
  }

  const WorkflowAborted = () => {
    const navigate = useNavigate()
    const isSubscriber = useIsSubscriber()
    const numCreditsAvailable = useCreditsAvailable()
    const md = useMyMonetizationData()
    const isBuyingEnabled = useIsBuyingEnabled()

    const reason = workflow.abort.reason
    if (reason === 'anonymous-upload') {
      return (
        <div>
          This video has not been analyzed yet. To analyze it, please create an
          account or login to your existing account.
        </div>
      )
    }
    if (reason === 'no-video-credits') {
      if (md.isLoading) {
        return <Spinner text='Loading...' />
      }

      // three cases:
      // 1) The user has one or more VCs and can use it to process their video.
      // 2) The user has no VCs AND is a subscriber. Ask if they want to buy VCs.
      // 3) The user has no VCs AND is not a subscriber. Ask if they subscribe.
      if (!isBuyingEnabled) {
        // on prod, we want to hide the unfinished parts below
        return <div>Free usage limit exceeded. Please contact support for unlock options.</div>
      }
      return (
        <div>
          This video was not yet analyzed.

          {numCreditsAvailable > 0 && (
            <div>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  console.log('TODO: call /video/reprocess API')
                }}
              >
                Analyze Video Now for 1 VC
              </Button>
            </div>
          )}
          {numCreditsAvailable <= 0 && (
            <div>
              {isSubscriber && (
                <div>
                  As a subscriber, your annual Credits will next refill{' '}
                  {new Date(md.currentSubscription.end).toLocaleString()}.

                  If you&apos;d like to analyze your video now, you can buy
                  more Credits now.
                  <Button
                    variant='contained' color='primary'
                    onClick={() => { navigate('/credits') }}
                  >
                    Buy Credits
                  </Button>
                </div>
              )}
              {!isSubscriber && (
                <div>
                  You have used all of the free credits included on your free trial. Please become a subscriber to analyze your video.
                  <Button
                    variant='contained' color='primary'
                    onClick={() => { navigate('/subscribe') }}
                  >
                    Explore Subscriber Options
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    return (
      <div>
        This workflow was aborted for an unknown reason: {reason}
        <div>Please reach out to support for help.</div>
      </div>
    )
  }

  function nonAbortBody () {
    return workflow?.error
      ? (
        <>
          <div className='heading'>
            <img src={emojiUrl} alt='emoji grimacing' />
            <strong className='title'>Oops! We hit a snag while analyzing your game</strong>
          </div>
          <Divider />
          <IfErrorStateExist />
        </>
        )
      : (
        <>
          <div className='heading'>
            <strong className='title'>📹 Our AI wasn’t able to analyze this video</strong>
          </div>
          <Divider />
          <div className='info-container'>
            <strong className='info-title'>
              This is likely a framing issue. Please verify that the video is properly framed for
              optimal use of our AI Engine.
            </strong>
            <div className='gif-container'>
              <img
                className='gif'
                src='https://fb-usercontent.fra1.cdn.digitaloceanspaces.com/0191c865-6aa7-70bf-ac16-9ad37392d279.gif'
                alt='correct frame gif'
              />
            </div>
            <div className='learn-more'>
              <Link to='https://roadmap.pb.vision/help/articles/1108176-video-recording-and-framing-tips'>
                <Button variant='contained' color='primary'>
                  Learn more
                </Button>
              </Link>
            </div>
          </div>
        </>
        )
  }

  return (
    <Container className={isMobile ? 'mobile' : ''}>
      {workflow?.abort ? <WorkflowAborted /> : nonAbortBody()}
    </Container>
  )
}

const Container = styled('div')({
  borderRadius: '12px',
  border: `1px solid ${COLORS['warning-500']}`,
  background: COLORS['warning-050'],
  color: COLORS['neutral-700'],
  fontSize: 14,
  gap: 16,
  marginTop: 16,
  padding: 32,
  ...column,
  '& .row': {
    alignItems: 'flex-end',
    ...row
  },
  ' .heading': {
    alignItems: 'center',
    gap: 10,
    ...row
  },
  '& img': {
    width: 25
  },
  '& hr': {
    width: '100%'
  },
  '& .buttons': {
    gap: 16,
    marginBlock: 8,

    ...row,
    '& .back': {
      color: COLORS.white,
      backgroundColor: COLORS['success-700']
    }
  },
  '& .title': {
    fontSize: 24
  },
  '& .info-container': {
    alignItems: 'flex-start',
    width: '100%',
    gap: 10,
    ...column,
    '& .info-title': {
      fontSize: 16
    }
  },
  '& .info': {
    gap: 8,
    flexShrink: 0,
    alignItems: 'flex-start',
    ...row,
    '& a': {
      color: COLORS['neutral-700']
    },
    '& div': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      '& strong': {
        textWrap: 'nowrap'
      }
    },
    '& svg': {
      minWidth: 24,
      '&.bad path': {
        fill: COLORS.error
      },
      '&.good path': {
        fill: COLORS['success-700']
      }
    }
  },
  '& .learn-more': {
    width: '100%',
    marginTop: 16,
    alignItems: 'center',
    gap: 8,
    ...row,
    '& button': {
      background: COLORS.white,
      border: `1px solid ${COLORS['neutral-300']}`,
      '&:hover': {
        background: COLORS['neutral-050'],
        borderColor: COLORS['neutral-400']
      }
    }
  },
  '& .gif-container': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  '.gif': {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },

  // responsive
  '&.mobile': {
    padding: 16,
    marginInline: 8,
    '& .info': {
      '& div': {
        ...column
      }
    }
  }
})
