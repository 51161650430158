import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
// import ButtonGroup from '@mui/material/ButtonGroup'
import Slider from '@mui/material/Slider'
import { useCallback } from 'react'

import { shotWindowMarks, shotTypes, rallySequence, /* customTags, */ /* highlights, */ players, groundStrokeOrVolley /* strokeSides, */, strokeTypes } from '../helpers'

import { ChipSelect } from './chip-select'
import { DirectionSelect } from './direction-select'
import { Drawer } from './drawer'

import HelpIcon from '@/assets/help.svg?react'
import ExperimentIcon from '@/assets/icons/experiment.svg?react'
import { Button } from '@/components/button'
import { SimpleButton } from '@/components/button/simple-button'
import { PlayerImage } from '@/components/player-image'
import { RangeSlider } from '@/components/range-slider'
import MultiWayToggle from '@/components/toggle/multi-way-toggle'
import { Tooltip } from '@/components/tooltip'
import { useRenamePlayer } from '@/pages/home/hooks/use-rename-player'
import cls from '@/utils/classnames'

export function FilterSideBar (props) {
  const { filters, stats, handleClearFilters, updateFilter, showDrawer, setShowDrawer, video, aiEngineVersion, userData, vid } = props
  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )
  const handleOpenPlayerImage = (evt) => {
    const target = evt.currentTarget
    target.previousElementSibling.firstElementChild.click()
  }

  const selectPlayer = useCallback((player) => {
    const update = filters.players.includes(player) ? filters.players.filter((p) => p !== player) : [...filters.players, player]
    // Prevent deselecting all of the players
    if (update.length) {
      updateFilter('players', update)
    }
  }, [filters.players, updateFilter])

  return (
    <Drawer
      anchor='right'
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
    >
      <div className='head'>
        Adjust Filters
        <SimpleButton onClick={() => setShowDrawer(false)}>
          <CloseIcon />
        </SimpleButton>
      </div>

      <div className='filters-content'>
        <div className='title'>Players<Button variant='outlined' color='midnight' className='neutral-outline' sx={{ marginLeft: 'auto' }} onClick={handleClearFilters}>Clear filters</Button></div>
        <section className='players'>
          {players.map((p) => (
            <div key={p} className={cls('p-wrap', filters.players.includes(p) && 'selected')}>
              <PlayerImage className={`img player${p + 1}`} scale={50} width={50} height={50} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={p} text={userData.players[p]?.name || `Player ${p + 1}`} onChange={onRenamePlayer(p)} />
              <SimpleButton className='edit-icon' sx={{ marginLeft: '4px' }} onClick={handleOpenPlayerImage}>
                <EditIcon />
              </SimpleButton>
              <SimpleButton className='button' onClick={() => selectPlayer(p)}>
                <span>{userData.players[p]?.name || `Player ${p + 1}`}</span>
                {filters.players.includes(p) ? <ToggleOnIcon /> : <ToggleOffIcon />}
              </SimpleButton>
            </div>
          ))}
        </section>
        <section className='experimental-icon'>
          <Tooltip orange title='This feature is new, so you may encounter some inaccuracies. Rest assured, it is getting smarter every day. We appreciate your patience as we train our AI.'>
            <ExperimentIcon />
          </Tooltip>
        </section>
        <section className='strike-types'>
          <MultiWayToggle options={strokeTypes} onChange={(update) => updateFilter('strokeType', update)} selected={filters.strokeType} />
          <MultiWayToggle options={groundStrokeOrVolley} onChange={(update) => updateFilter('groundStrokeOrVolley', update)} selected={filters.groundStrokeOrVolley} />

        </section>
        {/* <ButtonGroup className='stroke-sides'>
          {strokeSides.map(({ value, label }) => (
            <Button key={value} variant='outlined' color='midnight' className={cls('neutral-outline', filters.strokeSide === value && 'selected')} onClick={() => updateFilter('strokeSide', value)}>
              {label}
            </Button>
          ))}
        </ButtonGroup> */}
        <div className='title'>Shot Quality<Tooltip title='Quality estimates how well a shot is executed based on characteristics like speed, trajectory and bounce.'><HelpIcon /></Tooltip></div>
        <section className='shot-quality'>
          <RangeSlider min={0} max={1} start={filters.quality?.min} end={filters.quality?.max} step={0.2} onChange={(update) => updateFilter('quality', { min: update[0], max: update[1] })} />
          <div className='row'>
            <span className='poor'>Poor</span>
            <span className='excellent'>Excellent</span>
          </div>
        </section>
        <div className='title'>Shot Window<Tooltip title='View shots leading up to and following the selected shot. Example: "-2" will show 2 shots before the selection.'><HelpIcon /></Tooltip></div>
        <section className='shot-window'>
          <Slider
            min={-3}
            max={3}
            getAriaLabel={() => 'Shot Window'}
            value={[filters.shotWindow.numBefore, filters.shotWindow.numAfter]}
            onChange={(_evt, update) => updateFilter('shotWindow', { numBefore: update[0], numAfter: update[1] })}
            valueLabelDisplay='off'
            step={1}
            marks={shotWindowMarks}
            disableSwap
          />
        </section>
        <ChipSelect className='shot-types' selected={filters.types} options={shotTypes} badge={stats} onChange={(update) => updateFilter('types', update)} />
        <ChipSelect className='rally-sequence' title='Rally Sequence' selected={filters.sequences} options={rallySequence} badge={stats} onChange={(update) => updateFilter('sequences', update)} />
        {/* <ChipSelect className='custom-tags' title='Custom Tags' selected={filters.customTags} options={customTags} onChange={(update) => updateFilter('customTags', update)} /> */}
        {/* <ChipSelect className='highlights' title='Highlights' selected={filters.highlights} options={highlights} onChange={(update) => updateFilter('highlights', update)} /> */}
        <DirectionSelect className='directions' title='Direction' selected={filters.directions} onChange={(update) => updateFilter('directions', update)} />
      </div>
    </Drawer>
  )
}
