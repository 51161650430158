import clsx from 'clsx'
import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './styles.module.scss'

import { TextButton } from '@/components/button/text-button'
import { signOut } from '@/store/auth'

function Sidebar ({ items }) {
  const [activeId, setActiveId] = useState('account')
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [isSigningOut, setIsSigningOut] = useState(false)

  function signOutWrapper () {
    setIsSigningOut(true)
    signOut().finally(() => setIsSigningOut(false))
  }

  function onTabClickHandler (path) {
    setActiveId(path)
    navigate(`/settings/${path}`)
  }

  // Set the active tab based on the current path
  useLayoutEffect(() => {
    const path = window.location.pathname.split('/').pop()
    setActiveId(path)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const renderItems = () => (
    <>
      {items.map((item) => {
        const { title, icon: Icon, id, badge } = item
        const isActive = id === activeId
        return (
          <div key={id} className={clsx([styles.item, { [styles.active]: isActive }])} onClick={() => onTabClickHandler(id)}>
            <Icon className={styles.icon} />
            <div className={styles.titleSection}>
              <div className={styles.title}>{title}</div>
              {badge}
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <div className={styles.sidebar}>
      <div className={styles.items}>{renderItems()}</div>
      <TextButton
        className={styles.signOut}
        onClick={() => {
          signOutWrapper()
        }}
      >
        Sign out
      </TextButton>
    </div>
  )
}

export default Sidebar
