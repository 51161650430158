import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, styled, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import CourtCoverageIcon from '@/assets/icons/marketing/court-coverage.svg?react'
import HighlightsIcon from '@/assets/icons/marketing/highlights-star.svg?react'
// import PauseIcon from '@/assets/icons/marketing/pause.svg?react'
// import PlayIcon from '@/assets/icons/marketing/play.svg?react'
import ServeAndReturnIcon from '@/assets/icons/marketing/serve-and-return.svg?react'
// import ShotExplorerIcon from '@/assets/icons/marketing/shot-explorer.svg?react'
import ShotTrajectoriesIcon from '@/assets/icons/marketing/shot-trajectories.svg?react'
// import ShotExplorerImgPreview from '@/assets/images/marketing/shot-explorer.png'
import ShotTrajectoriesVideoPreview from '@/assets/videos/marketing/3d-trajectories.mp4'
import ShotCoverageVideoPreview from '@/assets/videos/marketing/coverage.mp4'
import ShotHighlightVideoPreview from '@/assets/videos/marketing/highlight.mp4'
import ShotServeReturnVideoPreview from '@/assets/videos/marketing/serve-return.mp4'
import GetOnMobile from '@/components/get-on-mobile/get-on-mobile'
import { AnonymousUploadButton } from '@/components/upload/UploadButton'
import { DeviceOS, useDeviceOS } from '@/hooks/use-device-os'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const ACCORDIONS = [

  {
    id: 'shot-trajectories',
    title: 'Shot Trajectories',
    description: 'Attain true shot mastery with our advanced 3D trajectory analysis. Whether it\'s a drop, drive, or lob, quickly review how well you execute each shot to refine your strategy and hone your technique.',
    icon: ShotTrajectoriesIcon,
    preview: {
      type: 'video',
      source: ShotTrajectoriesVideoPreview
    }
  },
  {
    id: 'court-coverage',
    title: 'Court Coverage',
    description: 'Track how much you move, your positioning with different partners, and your efficiency in reaching the kitchen line when serving. See how many shots you take versus your partner, and find out if you play better on the right or left side.',
    icon: CourtCoverageIcon,
    preview: {
      type: 'video',
      source: ShotCoverageVideoPreview
    }
  },
  {
    id: 'serve-return',
    title: 'Serve and Return',
    description: 'Monitor your serve and return depths and see how often you hit into the net or out of bounds. Compare your average serve and drive speeds with other players, and gain insights on how to stay ahead of the competition.',
    icon: ServeAndReturnIcon,
    preview: {
      type: 'video',
      source: ShotServeReturnVideoPreview
    }
  },
  {
    id: 'highlight',
    title: 'Highlights',
    description: 'Relive your best moments with automatic highlights. From epic long rallies and intense hand battles to perfectly executed Ernes and ATPs, - we capture it all for you - and make it easy to share your favorite shots with friends!',
    icon: HighlightsIcon,
    preview: {
      type: 'video',
      source: ShotHighlightVideoPreview
    }
  }
  // {
  //   id: 'shot-explorer',
  //   title: 'Shot Explorer',
  //   description: 'Curious why you\'re missing those backhand dinks? Dive deep into your game by filtering the exact shots you want to see. Filter by shot type—drive, drop, lob, smash, dink, Erne, and ATP. Or filter by quality to pinpoint your high and low-quality shots.',
  //   icon: ShotExplorerIcon,
  //   preview: {
  //     type: 'image',
  //     source: ShotExplorerImgPreview
  //   }
  // }
]

// DESKTOP VERSION WITH ACCORDIONS

const ACCORDION_DURATION = 10000 // ms
const ANIMATION_DURATION = 1000 // ms

/**
 * SingleAccordion component displays a single accordion with progress tracking.
 *
 * @param {string} props.id - The ID of the accordion.
 * @param {string} props.title - The title of the accordion.
 * @param {string} props.description - The description of the accordion.
 * @param {React.ElementType} props.icon - The icon component for the accordion.
 * @param {string} props.preview - The preview image URL/video for the accordion.
 * @param {boolean} props.expanded - Whether the accordion is expanded.
 * @param {boolean} props.play - Whether the accordion should play the progress animation.
 * @param {function} props.onSelectAccordion - Function to call when the accordion is selected.
 * @param {function} props.changeAccordion - Function to call to change to the next accordion after the progress reach 100%.
 * @param {function} props.setSavedState - Function to call to save the current progress state (When an accordion leaves the viewport, it pauses and saves its current progress).
 * @param {number} [props.savedProgress=0] - The saved progress state of the accordion.
 * @returns {JSX.Element} The SingleAccordion component.
 */

function SingleAccordion (props) {
  const [progress, setProgress] = useState(props.savedProgress || 0)
  const progressIntervalRef = useRef(null)

  useEffect(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current)
    }

    // Only set progress if the accordion is expanded
    if (props.expanded === props.id && props.play) {
      props.savedProgress === 0 && setProgress(0) // Reset progress when accordion is expanded
      setTimeout(() => {
        progressIntervalRef.current = setInterval(() => {
          setProgress((prevProgress) => {
            const nextProgress = prevProgress + 1
            if (nextProgress > 99) {
              props.changeAccordion()
              clearInterval(progressIntervalRef.current)
            }
            return nextProgress
          })
        }, ACCORDION_DURATION / 100)
      }, ANIMATION_DURATION) // Delay to allow the accordion to expand
    }

    // Clean up the interval on unmount or when dependencies change
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expanded, props.id, props.play])

  // This function is called when the accordion is clicked
  const handleAccordionChange = (event, isExpanded) => {
    setProgress(0) // Reset progress when accordion is clicked

    // Continue with the default behavior (expand clicked accordion)
    props.onSelectAccordion(props.id)()
  }

  useEffect(() => {
    if (progress > 0) {
      props.setSavedState(progress) // Save progress only when it's greater than 0
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  return (
    <AccordionContainer expanded={props.expanded === props.id} onChange={handleAccordionChange} slotProps={{ transition: { timeout: ANIMATION_DURATION } }}>
      <AccordionSummary>
        <props.icon />
        <strong>{props.title}</strong>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          {props.description}
        </p>
      </AccordionDetails>
      <LinearProgress variant='determinate' value={progress} />
    </AccordionContainer>
  )
}

const renderPreview = (preview) => {
  switch (preview.type) {
    case 'image':
      return <img src={preview.source} alt='Preview' />
    case 'video':
      return (
        <video
          src={preview.source} controls={false}
          autoPlay
          loop
          muted
          playsInline
        />
      )
    default:
      return null
  }
}

/**
 * DesktopExploreSection component displays the explore section with accordions for desktop.
 *
 * @returns {JSX.Element} The DesktopExploreSection component.
 */
function DesktopExploreSection () {
  const [expanded, setExpanded] = useState(ACCORDIONS[0].id)
  const [play, setPlay] = useState(false)
  const [manuallyPaused] = useState(false)
  const accordionContainerRef = useRef(null)
  const [savedProgress, setSavedState] = useState(0)

  const nextAccordion = useCallback(() => {
    setTimeout(() => {
      setSavedState(0)
      setExpanded((prevExpanded) => {
        const currentIndex = ACCORDIONS.findIndex((acc) => acc.id === prevExpanded)
        const nextIndex = (currentIndex + 1) % ACCORDIONS.length
        return ACCORDIONS[nextIndex].id
      })
    }, ANIMATION_DURATION)
  }, [])

  const saveProgressForAccordion = useCallback((progress) => {
    setSavedState(progress)
  }, [])

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setPlay(true)
        } else {
          setPlay(false)
        }
      })
    }

    // Observer is used to detect when the accordions are in the viewport, and to start the progress animation only when they are in the viewport
    const observerOptions = { threshold: 0.5 }
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(observerCallback, observerOptions)
    const currentRef = accordionContainerRef.current

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [])

  const handleSelectAccordion = (id) => () => {
    setExpanded(id)
  }

  return (
    <DesktopContainer>
      <div className='accordions' ref={accordionContainerRef}>
        {ACCORDIONS.map((accordion, i) => (
          <SingleAccordion
            key={i}
            {...accordion}
            play={play && !manuallyPaused}
            expanded={expanded}
            onSelectAccordion={handleSelectAccordion}
            changeAccordion={nextAccordion}
            setSavedState={saveProgressForAccordion}
            savedProgress={savedProgress}
          />
        ))}
        <AnonymousUploadButton style={{
          marginTop: 32,
          padding: '10px 40px',
          fontSize: '19x',
          alignSelf: 'flex-start'
        }}
        />
      </div>
      <div className='previews'>
        {ACCORDIONS.map((accordion, i) => (
          <div key={i} className={cls('preview', expanded === accordion.id && 'active')}>
            {renderPreview(accordion.preview)}
          </div>
        ))}
        {/* <div className='pause-play'>
          {!manuallyPaused ? <PauseIcon onClick={() => setManuallyPaused(true)} /> : <PlayIcon onClick={() => setManuallyPaused(false)} />}
        </div> */}
      </div>
    </DesktopContainer>
  )
}

// MOBILE VERSION WITH CARDS

/**
 * MobileExploreSection component displays the explore section for mobile devices.
 *
 * @returns {JSX.Element} The MobileExploreSection component.
 */
function MobileExploreSection () {
  const { deviceOS } = useDeviceOS()
  const isOtherDevices = deviceOS === DeviceOS.OTHER
  return (
    <MobileContainer>
      {ACCORDIONS.map((accordion, i) => {
        return (
          <div key={i} className='single-item'>
            <div className='summary'>
              <accordion.icon />
              <strong>{accordion.title}</strong>
            </div>
            <p>{accordion.description}</p>
            {renderPreview(accordion.preview)}
          </div>
        )
      })}
      {isOtherDevices && (
        <div className='store-icons'>
          <GetOnMobile deviceOs={DeviceOS.ANDROID} />
          <GetOnMobile deviceOs={DeviceOS.IOS} />
        </div>
      )}
      {!isOtherDevices && <GetOnMobile />}
    </MobileContainer>
  )
}

export function ExploreLandingSection () {
  const isDesktop = useMediaQuery('(min-width:900px)')
  return isDesktop ? <DesktopExploreSection /> : <MobileExploreSection />
}

const MobileContainer = styled('div')({
  ...column,
  gap: '48px',
  padding: '0 16px',
  '& .single-item': {
    ...column,
    '& img, video': {
      marginInline: '-15px'
    },
    '& .summary': {
      ...row,
      alignItems: 'center',
      gap: '16px',
      marginTop: '16px',
      '& strong': {
        fontSize: '24px',
        lineHeight: '34px',
        color: COLORS.black
      }
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '25px',
      fontWeight: 300,
      marginBottom: '40px'
    }
  },
  '& .download-button': {
    width: '60%'
  }
})

const DesktopContainer = styled('div')({
  ...row,
  marginTop: '100px',
  '& .accordions': {
    ...column,
    width: '50%',
    '& .get-started-button': {
      marginTop: 32,
      padding: '10px 40px',
      fontSize: '19x',
      alignSelf: 'flex-start'
    }
  },
  '& .previews': {
    display: 'flex',
    position: 'relative',
    flex: 1,
    width: '50%',
    '& .preview': {
      position: 'absolute',
      opacity: 0,
      maxHeight: '100%',
      left: '100px',
      top: 0,
      bottom: '10%',
      '& img': {
        height: '100%'
      },
      '& video': {
        height: '100%'
      }
    },
    '& .preview.active': {
      opacity: 1,
      left: '20px',
      transition: 'opacity 1s ease, left 1s ease'
    },
    '& .pause-play': {
      position: 'absolute',
      cursor: 'pointer',
      bottom: '0',
      left: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px',
      width: '30px',
      height: '30px',
      backgroundColor: COLORS['primary-500'],
      zIndex: 10,
      '& svg': {
        width: '20px',
        height: '20px',
        color: COLORS.white
      }
    }
  }
})

const AccordionContainer = styled(Accordion)({
  boxShadow: 'none', // Remove default shadow
  background: 'none', // Remove background
  '&:before': {
    display: 'none' // Remove the before pseudo-element that adds a border
  },
  '&.Mui-expanded': {
    backgroundColor: COLORS['neutral-100'],
    borderRadius: '4px',
    overflow: 'hidden',
    transition: '1s ease',
    '& .MuiAccordionSummary-content': {
      '& strong': {
        color: COLORS.black,
        transition: 'color 1s ease'
      }
    },
    '& .MuiAccordionDetails-root': {
    }
  },
  '& .MuiAccordionSummary-content': {
    gap: '15px',
    alignItems: 'center',
    padding: '0 30px',
    transition: 'padding 1s ease',
    '& strong': {
      fontSize: '32px',
      lineHeight: '45.44px',
      color: COLORS['neutral-400']
    },
    '& svg': {
      width: '24px',
      height: '24px',
      color: COLORS['neutral-400']
    }
  },
  '& .MuiButtonBase-root': {
    padding: 0,
    margin: 0
  },
  '& .MuiAccordionDetails-root': {
    padding: '0 20px 20px 0',
    paddingLeft: '30px',
    transition: 'padding 1s ease',
    '& p': {
      fontSize: '20px',
      lineHeight: '32.4px',
      color: COLORS.black,
      fontWeight: 300
    }
  }
})
