import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { createLocalStorageState } from './local-storage-state'

import { useMonetizationData } from '@/hooks/monetization'
import { apisSlice } from '@/store/apis'
import { now } from '@/utils'
import { callAPIFromThunk } from '@/utils/api'
import { getAuth } from '@/utils/firebase'
import { notifyAnonymousUserTemporaryId, notifySignedIn, notifySignedOut, webviewLocalStorageKey } from '@/utils/mobile-app-communication'

/**
 * Returns true if an id string is an anonymous user ID.
 */
export function isAnonymousUserId (idString) {
  return !idString || idString.startsWith('anonymous-$device:')
}

export const sliceName = 'auth'
const { defaultState, preloadedState, saveState } = createLocalStorageState({
  sliceName,
  defaultState: {
    savable: {
      anonymousId: undefined,
      uid: undefined,
      displayName: undefined,
      email: undefined,
      emailVerified: undefined,
      providerId: undefined,

      // undefined customData means data is loading if user is logged in and
      // customDataLoadError is null. Custom data is our custom User data model.
      customData: undefined
    },
    ephemeral: {
      customDataLoadError: null,
      firebaseIsLoading: true, // true until the first callback is received from auth lib
      mixpanelIsLoading: true,
      token: undefined,
      getTokenError: undefined,
      showAlert: undefined
    }
  }
})

export const userChanged = createAction('userChanged')

export const authSlice = createSlice({
  name: sliceName,
  initialState: preloadedState,
  reducers: {
    loadedCustomData: (state, action) => {
      state.customData = action.payload
      state.customDataLoadError = null
      saveState(state)
    },
    failedToLoadCustomData: (state, action) => {
      state.customData = undefined
      state.customDataLoadError = action.payload
      saveState(state)
    },
    mixpanelSDKLoaded: state => {
      state.mixpanelIsLoading = false
    },
    clearAnonymousId: state => {
      state.anonymousId = undefined
      saveState(state)
    },
    gotNewAnonymousId: (state, action) => {
      if (!isAnonymousUserId(action.payload)) {
        throw new Error(`invalid anonymous id ${action.payload}`)
      } else if (!state.anonymousId) {
        state.anonymousId = action.payload
        saveState(state)
      }
    },
    refreshedUser: (state, action) => {
      Object.assign(state, action.payload)
      state.customData = undefined
      state.customDataLoadError = null
      state.anonymousId = undefined
      state.firebaseIsLoading = false
      saveState(state)
    },
    setPushToken: (state, action) => {
      const pushToken = action.payload
      if (state.customData) {
        state.customData.pushToken = pushToken
      }
    },
    tokenUpdated: (state, action) => {
      state.token = action.payload.token
      state.getTokenError = action.payload.error
    },
    updateRecentlyTagged: (state, action) => {
      state.customData.recentlyTagged = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userChanged, (state, action) => {
      if (action.payload) {
        // payload => user signed in
        Object.assign(state, action.payload)
        state.customData = undefined
        state.customDataLoadError = null
      } else {
        // no payload => clear all data
        Object.assign(state, defaultState)
      }
      // always clear the anonymous id:
      //  * if the user logged in, they are no longer anonymous
      //  * if the user logged out, their old anonymous id if set will change
      state.anonymousId = undefined
      state.firebaseIsLoading = false
      saveState(state)
    })
    builder.addCase(updateCurrentUser.fulfilled, (state, action = {}) => {
      if (action.payload) {
        Object.assign(state, action.payload)
      }
    })
  }
})

export const { reducer } = authSlice
export const { setPushToken } = authSlice.actions

/** Returns the user's current access token (refreshes if expired) */
export async function getLoggedInUserToken () {
  return getAuth().currentUser.getIdToken()
}

/** Returns information about the current logged in user (if logged in) */
export const selectLoggedInUser = createSelector([state => state[sliceName]], (authState) => {
  const { customData, ...rest } = authState
  return { ...(customData ?? {}), ...rest }
})

/** Returns whether the user is logged in */
export const selectIsLoggedIn = state => state[sliceName].uid !== undefined
export const useIsLoggedIn = () => useSelector(selectIsLoggedIn)

/** Returns whether we're loading extra data about the user */
export const useIsLoadingExtraUserData = () => {
  return useSelector(state => {
    const auth = state[sliceName]
    return selectIsLoggedIn(state) && !auth.customData && !auth.customDataLoadError
  })
}

/** Returns the error encountered when trying to load custom user data, if any */
export const useCustomUserDataLoadError = () => useSelector(state => state[sliceName].customDataLoadError)

/** Returns whether firebase auth is done initializing */
export const selectIsAuthInitialized = state => !state[sliceName].firebaseIsLoading
export const useIsAuthInitialized = () => useSelector(selectIsAuthInitialized)

export function useIsMixpanelReady () {
  return useSelector(s => !s[sliceName].mixpanelIsLoading)
}

const syncAnonymousId = () => (dispatch, getState) => {
  // won't know if we need an anonymous id until after firebase auth is loaded
  const state = getState()
  const isAuthReady = selectIsAuthInitialized(state)
  if (!isAuthReady) {
    return
  }

  const isLoggedIn = selectIsLoggedIn(state)
  if (isLoggedIn) {
    dispatch(authSlice.actions.clearAnonymousId())
  } else {
    let mpId = mixpanel.get_distinct_id()
    if (!mpId.startsWith('$device:')) {
      mixpanel.reset()
      mpId = mixpanel.get_distinct_id()
    }
    const anonymousId = `anonymous-${mpId}`
    dispatch(authSlice.actions.gotNewAnonymousId(anonymousId))
    notifyAnonymousUserTemporaryId(getState()[sliceName].anonymousId)
  }
}

/**
 * Called whenever the user auth status changes (and on startup). And listen for rf code
 *
 * @param {object} isLoggedIn
 *
 * @returns {undefined} nothing
 */
const handleReferralCode = (isLoggedIn) => async (dispatch) => {
  const referralCode = JSON.parse(window.localStorage.getItem('tmp-referral-code'))

  if (referralCode) {
    // Set the referral code in store if the user is not logged in to use it if
    // user decide to login or register and report to mixpanel
    if (!isLoggedIn) {
      mixpanel.track('referral_click', { referralCode, result: 'anonymous' })
    } else {
      /**
       * When a user is logged in, we need to report that to server
      */
      const res = await dispatch(reportReferralCode(referralCode))
      if (res.result === 'success-new' || res.result === 'success-overwrote') {
        await dispatch(fetchCustomUserData())
      }
      mixpanel.track('referral_click', { referralCode, result: res.result })

      // After the action is completed, we delete the referral code from the store to prevent unwanted server calls.
      window.localStorage.removeItem('tmp-referral-code')
    }
  }
}

/**
 * Called whenever the user changes (login and logout, and on startup).
 *
 * @param {object} user Firebase user object
 *
 * @returns {undefined} nothing
 */
export const firebaseAuthStateChanged = (user) => async (dispatch, getState) => {
  const state = getState()
  const currentUID = state[sliceName].uid
  const newUID = user ? user.uid : undefined
  const isLoggedIn = !!newUID

  if (!newUID) {
    // is not signed in
    dispatch({ type: 'userChanged' }) // clear the data
    if (currentUID) {
      // going from signed in (currentUID was set) to signed out
      mixpanel.reset()
      notifySignedOut()
      dispatch(apisSlice.util.resetApiState())
    } // else: no change was logged and is still logged out
    Sentry.setUser({
      ip_address: '{{auto}}',
      id: getState()[sliceName].anonymousId
    })
    dispatch(syncAnonymousId())
    dispatch(handleReferralCode(isLoggedIn))
  } else {
    // logged in
    mixpanel.identify(user.uid)
    const isSameUser = state[sliceName].uid === user.uid
    const payload = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      providerId: user.providerData[0].providerId // 'password' | 'google.com' | 'apple.com'
      // providerId: user.providerId, // This will always be 'firebase'
    }

    if (isSameUser) {
      delete payload.uid // no change
      dispatch(authSlice.actions.refreshedUser(payload))
    } else {
      dispatch({ type: 'userChanged', payload })
    }
    if (newUID !== currentUID) {
      dispatch(apisSlice.util.resetApiState())
    }
    dispatch(syncAnonymousId())
    Sentry.setUser({
      ip_address: '{{auto}}',
      id: user.uid,
      email: user.email
    })

    try {
      const token = await getLoggedInUserToken()
      await dispatch(fetchCustomUserData())

      notifySignedIn({
        email: user.email,
        uid: user.uid,
        token
      })
      dispatch(handleReferralCode(isLoggedIn))
      dispatch(refreshTokenAsNeeded())
    } catch (err) {
      dispatch(authSlice.actions.failedToLoadCustomData(err.toString() || 'unknown error'))
    }
  }
}

let refreshTimer = null
function refreshTokenAsNeeded () {
  return async dispatch => refreshTokenAsNeededHelper(dispatch, false)
}
export function refreshToken () {
  return async dispatch => refreshTokenAsNeededHelper(dispatch, true)
}
async function refreshTokenAsNeededHelper (dispatch, forceRefresh) {
  const currentUser = getAuth().currentUser
  if (!currentUser) {
    dispatch(authSlice.actions.tokenUpdated({}))
    return // user must've signed out
  }
  let result
  try {
    result = await currentUser.getIdTokenResult({ forceRefresh })
  } catch (err) {
    dispatch(authSlice.actions.tokenUpdated({ error: `failed to load auth token: ${err.toString()}` }))
    return
  }
  const { expirationTime, token } = result
  dispatch(authSlice.actions.tokenUpdated({ token }))
  const expireEpoch = Math.floor(new Date(expirationTime).getTime() / 1000)
  // refresh ~5min before it expires
  const refreshSecs = Math.max(0, expireEpoch - now() - 300)
  if (refreshTimer) {
    clearTimeout(refreshTimer)
  }
  refreshTimer = setTimeout(() => {
    refreshTokenAsNeededHelper(dispatch, true)
  }, refreshSecs * 1000)
}

export function useLoggedInUserCredentials () {
  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()
  const token = useSelector(state => state[sliceName].token)
  const getTokenError = useSelector(state => state[sliceName].getTokenError)
  const userId = useUserId() // could be uid or anonymous id
  const uid = isAuthReady && isLoggedIn && !isAnonymousUserId(userId) ? userId : null
  return useMemo(() => uid ? { getTokenError, token, uid } : {}, [
    getTokenError, token, uid])
}

const mixpanelToken = import.meta.env.PROD ? '78c48e38f59ab21c1850740e2bb4ecff' : '52bd993b07bdba759c2f141345e7c32a'

/** Initializes the mixpanel SDK */
export function initMixpanel (store) {
  mixpanel.init(mixpanelToken, {
    debug: false,
    persistence: 'localStorage',
    loaded: () => {
      store.dispatch(authSlice.actions.mixpanelSDKLoaded())
      store.dispatch(syncAnonymousId())
    }
  })
}

/**
 * @returns {string|undefined} the anonymous id string (undefined if the user
 *   is not logged OR we have not yet determined the anonymous user ID [though
 *   usually that happens nearly instantly])
 */
export function useAnonymousId () {
  return useSelector(state => state[sliceName].anonymousId)
}

/**
 * @returns {string|undefined} the user id string (anonymousId if the user
 *   is not logged OR we have not yet determined the anonymous user ID [though
 *   usually that happens nearly instantly])
 */
export function useUserId () {
  return useSelector(selectUserId)
}
export function selectUserId (state) {
  return state[sliceName].uid ?? state[sliceName].anonymousId
}

/**
 * @returns {object} Returns information about the current logged in user (if logged in)
 */
export function useLoggedInUser () {
  const loggedInUser = useSelector(selectLoggedInUser)
  const isLoggedIn = useIsLoggedIn()
  return isLoggedIn ? loggedInUser : null
}

export const updateCurrentUser = createAsyncThunk(
  '/user/update',
  async (data, { getState }) => {
    const authState = getState()[sliceName]
    const token = await getLoggedInUserToken()

    // return await fetch('/user/update', {
    return await fetch(`${import.meta.env.VITE_API_SERVER}/user/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-uid': authState.uid,
        'x-token': token
      },
      body: JSON.stringify(data)
    }).then(
      (res) => {
        // user/update does not return anything so just use the sent data when status is ok
        if (res.status === 200) return data
        return {}
      }
    )
  })

export async function signOut () {
  // clear all local storage data except the webview version info, if any
  const webviewData = window.localStorage.getItem(webviewLocalStorageKey)
  window.localStorage.clear()
  if (webviewData) {
    window.localStorage.setItem(webviewLocalStorageKey, webviewData)
  }
  await getAuth().signOut()
}

export function useMyMonetizationData () {
  const loggedInUser = useLoggedInUser()
  return useMonetizationData(loggedInUser)
}

export function useUserHasFlag (flag) {
  const loggedInUser = useLoggedInUser()
  const flags = loggedInUser?.flags ?? []
  return flags.indexOf(flag) !== -1
}

export function useIsSubscriber () {
  const { isSubscriber } = useMyMonetizationData()
  return isSubscriber
}

export function useCreditsAvailable () {
  return useMyMonetizationData().creditsLeft
}

function reportReferralCode (code) {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.reportReferralCode, { code })
  }
}

function fetchCustomUserData () {
  return async (dispatch, getState) => {
    return await callAPIFromThunk(dispatch, getState, apisSlice.endpoints.fetchCustomUserData, {})
  }
}
