import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StarsIcon from '@mui/icons-material/Stars'
import { Dialog, styled } from '@mui/material'
import React from 'react'

import DallasFlash from '@/assets/images/marketing/dallas-flash-partner.png'
import Scorpions from '@/assets/images/marketing/scorpions-partner.png'
import { Button } from '@/components/button'
import { useIsBuyingEnabled } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import useZendesk from '@/hooks/use-zendesk'
import { CheckoutForm } from '@/pages/stripe'
import { useMyMonetizationData } from '@/store/auth'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { isInIOSWebview } from '@/utils/mobile-app-communication'

function ProfessionalPlanCustomPart () {
  return (
    <div className='professional-custom-part'>
      <p className='description'>Our Professional plan can be customized to meet your specific needs, with options to present insights in your preferred format.</p>
      <div className='teams-section'>
        <p>Currently in use by the following teams:</p>
        <div className='team-images'>
          <img src={Scorpions} alt='Coachella Valley Scorpions' />
          <img src={DallasFlash} alt='Dallas Flash' />
        </div>
        <a href='#faq'>More information</a>
      </div>
    </div>
  )
}

function PlanItem (props) {
  const { item } = props

  const ItemIcon = item.gold ? StarsIcon : CheckCircleIcon
  return (
    <div className={cls('plan-item', item.gold && 'gold')} key={item.title}>
      <ItemIcon className='item-icon' />
      <div className='item-description'>
        {item.comingSoon ? <p className='coming-soon'>Coming soon!</p> : null}
        <p className='item-name'>{item.text}</p>
      </div>
    </div>
  )
}

function SinglePlan ({ plan, purchaseFlow }) {
  const md = useMyMonetizationData()
  const { toggleZendeskWidget } = useZendesk()
  const isBuyingDisabled = !useIsBuyingEnabled()

  const disabled = plan.actionButton.isDisabled?.(md) || (plan.tier && isBuyingDisabled)
  return (
    <div className='pricing-plan'>
      <div className='plan-title'>
        {plan.title}
      </div>
      <div className='plan-price'>
        {plan.oldPrice && <span>{plan.oldPrice}</span>}
        {plan.price}
      </div>
      <div className='plan-billing'>
        {plan.billing}
      </div>
      {plan.description &&
        <div className='plan-description'>
          {plan.description}
        </div>}
      <Button
        className='try-button'
        onClick={() => plan.actionButton.action(purchaseFlow, toggleZendeskWidget)}
        disabled={disabled}
      >
        {(isBuyingDisabled && plan.tier)
          ? 'Coming soon!'
          : (plan.tier && md.currentSubscription?.tier === plan.tier)
              ? 'Already Subscribed'
              : plan.actionButton.text}
      </Button>
      <div className='credit-card-info'>
        {plan.creditCardInfo}
      </div>
      {plan.everythingFromInfo &&
        <div className='plan-billing'>
          {plan.everythingFromInfo}
        </div>}
      <div className='plan-items'>
        {plan.items.map((item, i) => <PlanItem key={i} item={item} />)}
      </div>
      {plan.customSection}
    </div>
  )
}

export default function PricingPlansSection () {
  const isMobile = useMobileDetect()
  const { toggleZendeskWidget } = useZendesk()
  const purchaseFlow = usePurchaseFlow()

  return (
    <>
      <Container id='plans' className={isMobile && 'mobile'}>
        {PRICING_PLANS.map((plan, i) => <SinglePlan key={i} plan={plan} purchaseFlow={purchaseFlow} />)}
        <div className='get-in-touch'>
          <p className='title'>Not sure which plan it right for you?</p>
          <p className='description'>
            <span onClick={toggleZendeskWidget}>Get in touch</span> - we’re happy to discuss and answer any questions you have.
          </p>
        </div>
      </Container>
      <Dialog open={Boolean(purchaseFlow.skuToBuy)} onClose={() => purchaseFlow.clearSKUToBuy()}>
        <CheckoutForm productId={purchaseFlow.skuToBuy} />
      </Dialog>
    </>
  )
}
export const PLAN_COSTS_ANNUAL = {
  starter: 180,
  premium: isInIOSWebview() ? 399 : 396 // Apple doesn't support $396 like other platforms
}

const PRICING_PLANS = [
  {
    title: 'Starter',
    tier: 'starter',
    oldPrice: '$20',
    price: `$${Math.round(PLAN_COSTS_ANNUAL.starter / 12).toFixed(0)}/mo`,
    billing: `Billed at $${PLAN_COSTS_ANNUAL.starter} annually`,
    actionButton: {
      text: 'Subscribe now',
      action: purchaseFlow => purchaseFlow.subscribeToPlan('starter'),
      isDisabled: md => md.isSubscriber
    },
    creditCardInfo: 'No credit card required',
    items: [
      { text: 'Tag other players to share insights for free' },
      { text: 'Auto rally slicing (dead time removal)' },
      { text: 'Create highlight reels', comingSoon: true },
      { text: 'Download advanced stats' },
      { text: '10GB free video storage' },
      { text: 'View at 1080p and 30fps' },
      { text: <>Includes <a href='#faq' onClick={() => window.history.replaceState(null, '', `${window.location.pathname}?faq=0`)}>60 Credits</a></> }
    ]
  },
  {
    title: 'Premium',
    tier: 'premium',
    oldPrice: '$50',
    price: `$${Math.round(PLAN_COSTS_ANNUAL.premium / 12).toFixed(0)}/mo`,
    billing: `Billed at $${PLAN_COSTS_ANNUAL.premium} Annually`,
    actionButton: {
      text: 'Subscribe now',
      action: purchaseFlow => purchaseFlow.subscribeToPlan('premium'),
      isDisabled: md => md.currentSubscription?.tier === 'premium'
    },
    creditCardInfo: 'No credit card required',
    everythingFromInfo: 'Everything in Starter, plus..',
    items: [
      { text: <>Explore shot by shot analysis with <strong>Shot Explorer</strong></>, gold: true },
      { text: 'Unlimited free video storage' },
      { text: 'Viewing at 4k and 60fps' },
      { text: <>Includes <a href='#faq' onClick={() => window.history.replaceState(null, '', `${window.location.pathname}?faq=0`)}>180 Credits</a> (3x over Starter)</> }
    ]
  },
  {
    title: 'Professional',
    price: 'Get in touch',
    billing: 'Pro Teams, Facilities, and Coaches',
    actionButton: {
      text: 'Contact us',
      action: (purchaseFlow, toggleZendeskWidget) => toggleZendeskWidget()
    },
    creditCardInfo: '',
    items: [
      { text: 'Everything from the Starter and Premium packages' }
    ],
    customSection: <ProfessionalPlanCustomPart />
  }
]

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  flexWrap: 'wrap',

  // font-sizes
  '& .plan-title, .item-name, a': {
    fontSize: '20px'
  },

  '& .pricing-plan': {
    ...column,
    width: '360px',
    padding: '36px 28px 50px',
    border: `1px solid ${COLORS['neutral-400']}`,
    borderRadius: '8px',
    minHeight: '738px',
    gap: '10px',

    '& .plan-title': {
      color: COLORS.black,
      fontWeight: 700,
      lineHeight: '138%'
    },
    '& .plan-price': {
      color: COLORS.black,
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '142%',
      '& span': {
        textDecoration: 'line-through',
        color: COLORS['neutral-500'],
        fontSize: '36px',
        marginRight: 10
      }
    },
    '& .plan-billing': {
      color: COLORS['neutral-500'],
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '138%'
    },
    '& .plan-description': {
      color: COLORS.black,
      fontWeight: 400,
      lineHeight: '158%'
    },
    '& .try-button': {
      backgroundColor: COLORS['primary-500'],
      color: 'white',
      width: '100%',
      minHeight: '40px',
      padding: '4px 16px',
      borderRadius: '8px'
    },
    '& .credit-card-info': {
      height: '20px',
      color: COLORS['neutral-800'],
      marginBottom: '10px',
      marginTop: '10px',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '138%'
    },
    '& .plan-items': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      '& .plan-item': {
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        gap: '10px',
        justifyContent: 'flex-start',
        '& .item-icon': {
          fontSize: '24px',
          color: COLORS['primary-500']
        },
        '& .item-name': {
          color: COLORS.black,
          fontWeight: 400,
          lineHeight: '138%',
          '& a': {
            fontWeight: 600
          }
        },
        '& .coming-soon': {
          color: COLORS['warning-600'],
          fontWeight: 700,
          fontSize: '14px',
          textTransform: 'uppercase'
        },
        '&.gold': {
          backgroundColor: COLORS['warning-050'],
          '& .item-icon': {
            color: COLORS.player3
          }
        }
      }
    }
  },
  '& .professional-custom-part': {
    ...column,
    flex: 1,
    '& .description': {
      marginBottom: 'auto',
      marginTop: '10px'
    },
    '& .team-images': {
      ...row,
      gap: '10px',
      marginBlock: '10px',
      '& img': {
        width: '75px',
        aspectRatio: 1
      }
    }
  },
  '& .get-in-touch': {
    textAlign: 'center',
    paddingTop: '72px',
    '& .title': {
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: '34.56px',
      letterSpacing: '-1px',
      marginBottom: '16px'
    },
    '& .description': {
      fontSize: '24px',
      lineHeight: '25.29px',
      '& span': {
        color: COLORS['primary-500'],
        cursor: 'pointer'
      }
    }
  },
  '&.mobile': {
    gap: '16px',
    fontSize: '14px',
    '& .pricing-plan': {
      width: '100%',
      padding: '26px 22px 50px',
      minHeight: 'unset'
    },
    '& .plan-title, .item-name, .item-description, a': {
      fontSize: '16px'
    },
    '& .plan-price': {
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: 0,
      '& span': {
        fontSize: '20px'
      }
    },
    '& .credit-card-info': {
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: 0
    },
    '& .professional-custom-part': {
      '& .description': {
        marginBottom: '50px'
      }
    },
    '& .get-in-touch': {
      paddingBlock: '32px',
      '& .title': {
        fontSize: '24px',
        lineHeight: '24.56px'
      },
      '& .description': {
        fontSize: '16px',
        lineHeight: '20px'
      }
    }
  }
})
