import { styled } from '@mui/material/styles'

export const Container = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '&.loading > *': {
    opacity: 0.5,
    pointerEvents: 'none',
    '&.title': {
      opacity: 1,
      color: 'rgb(23 26 28 / 50%)'
    }
  },
  '& .title': {
    display: 'flex',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '133%',
    color: '#171A1C',
    width: '100%',
    position: 'relative',
    '& button': {
      marginLeft: 'auto'
    }
  },
  '& .sendEmailButton': {
    width: '100%',
    height: '38px',
    marginTop: '11px',
    border: '1px solid #9FA6AD',
    borderRadius: '8px',
    color: 'white',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    backgroundColor: '#13D208',
    '&:hover': {
      backgroundColor: '#13D208'
    },
    '&.disabled': {
      backgroundColor: '#F0F4F8',
      borderColor: '#F0F4F8',
      color: '#9FA6AD'
    }
  },
  '& .saveChangesButton': {
    width: '160px',
    height: '38px',
    marginTop: '11px',
    border: '1px solid #9FA6AD',
    borderRadius: '8px',
    color: 'white',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    backgroundColor: '#13D208',
    '&:hover': {
      backgroundColor: '#13D208'
    },
    '& .icon': {
      color: 'white'
    },
    '&.disabled': {
      backgroundColor: '#F0F4F8',
      borderColor: '#F0F4F8',
      color: '#9FA6AD',
      '& .icon': {
        color: '#9FA6AD'
      }
    }
  },
  '& .avatarSection': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    marginTop: '16px',
    '& .avatar': {
      width: '100px',
      height: '100px',
      border: '1px solid #CDD7E1',
      borderRadius: '50px',
      backgroundColor: '#DDE7EE',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .icon': {
        color: '#9FA6AD',
        fontSize: '62px'
      }
    }
  },
  '& .textField': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    '& .label': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%',
      color: '#171A1C'
    },
    '& .muiRoot': {
      marginTop: '6px',
      width: '100%',
      input: {
        padding: '8px 12px !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        backgroundColor: '#FBFCFE',
        '&::placeholder': {
          color: '#555E68'
        }
      }
    }
  },
  '& .divider': {
    width: '100%',
    marginTop: '24px',
    marginBottom: '24px',
    color: '#636B744D'
  },
  '& .nameSection': {
    display: 'flex',
    gap: '16px',
    marginTop: '24px',
    alignItems: 'flex-end',
    '& .firstName, & .lastName': {
      width: '258px',
      flex: 1
    }
  },
  '& .inputSection': {
    display: 'flex',
    gap: '16px',
    marginTop: '24px',
    alignItems: 'flex-end',
    width: '100%',

    '& .textField': {
      flexGrow: 1
    }
  },
  '& .emailSection': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: '24px',
    marginTop: '24px',
    '& .email': {
      flex: 1,
      width: '426px'
    }
  },
  '& .resetPasswordSection': {
    '& .title': {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
      color: '#1E1E1E'
    },
    '& .subTitle': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#171A1C',
      marginTop: '8px'
    },
    '& .associatedEmailSection': {
      width: '272px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '16px',
      '& .email': {
        '& .muiRoot': {
          input: {
            backgroundColor: 'white'
          }
        }
      }
    }
  },
  '& .emailNotificationsSection, & .productUpdatesSection': {
    marginTop: '24px',
    '& .formControl': {
      marginRight: '0',
      '& .checkbox': {
        '& svg': {
          fontSize: '20px'
        }
      },
      '& .label': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#171A1C'
      }
    }
  },
  '& .deleteAccount': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '166%',
    color: '#171A1C',
    '& .contactSupport': {
      marginLeft: '4px',
      color: '#13940C',
      textDecoration: 'none'
    }
  },
  '& .signOut': {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#FF2C2C',
    cursor: 'pointer'
  },
  '&.mobile .deleteAccount': {
    display: 'block'
  }
})
