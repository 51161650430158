import FlagIcon from '@mui/icons-material/FlagOutlined'
import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

import TrophyIcon from '@/assets/icons/trophy-icon.svg?react'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function PricingAmbassadorProgramSection () {
  const isMobile = useMobileDetect()
  return (
    <Container id='ambassador-program' className={isMobile && 'mobile'}>
      <TrophyIcon />
      <p className='title'>Ambassador Program</p>
      <p className='subtitle'>Get Free Credits When You Refer a Friend</p>
      <p className='description'>Invite your friends and fellow players to experience the benefits of PB Vision for themselves.</p>
      <div className='how-it-works'>
        <p className='how-it-works-title'>How It Works:</p>
        {HOW_IT_WORKS.map((item, key) => (
          <div className='item' key={key}>
            <div className='order'>{key + 1}</div>
            <p>{item.item}</p>
          </div>
        ))}
        <div className='enroll'>
          <p className='title-md'>Enroll with one click 👉</p>
          <Button className='green' LinkComponent={Link} to='/settings/ambassador#enroll'>Enroll Now <FlagIcon /></Button>
        </div>
      </div>
    </Container>
  )
}

const HOW_IT_WORKS = [
  {
    item: <>Share your <Link to='/settings/ambassador'>unique referral link</Link> with friends, teammates, or fellow pickleball lovers.</>
  },
  {
    item: <>When they become a paid subscriber to any of our plans, you&apos;ll receive 15 Credits. They&apos;ll receive 15 bonus Credits too!</>
  },
  {
    item: 'Celebrate, there is no step 3!'
  }
]

const Container = styled('div')({
  ...column,
  alignItems: 'center',
  gap: '16px',
  flexWrap: 'wrap',
  textAlign: 'center',
  '& .title': {
    fontSize: '40px',
    fontWeight: 700
  },
  '& .subtitle': {
    fontSize: '30px',
    fontWeight: 700
  },
  '& .description': {
    fontSize: '24px',
    fontWeight: 300,
    maxWidth: 700,
    lineHeight: '24px'
  },
  '& .how-it-works': {
    ...column,
    width: '100%',
    gap: 30,
    alignItems: 'flex-start',
    marginTop: 25,
    '& .how-it-works-title': {
      fontSize: '24px',
      fontWeight: 700
    },
    '& .item': {
      ...row,
      alignItems: 'center',
      gap: 26,
      '& .order': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        minWidth: 50,
        aspectRatio: 1,
        borderRadius: 999,
        backgroundColor: COLORS['primary-500'],
        color: COLORS.white,
        fontSize: '27px'
      },
      '& p': {
        fontSize: 24,
        textAlign: 'start',
        lineHeight: '27px'
      }
    },
    '& .enroll': {
      ...row,
      gap: 8,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& a': {
        fontSize: '21px',
        padding: '15px 20px',
        '& svg': {
          fontSize: '30px'
        }
      }
    }
  },
  '&.mobile': {
    '& .title': {
      fontSize: '24px'
    },
    '& .subtitle': {
      fontSize: '20px',
      lineHeight: '20px'
    },
    '& .description': {
      fontSize: '16px',
      lineHeight: '16px'
    },
    '& .how-it-works': {
      gap: 16,
      marginTop: 15,
      '& .how-it-works-title': {
        fontSize: '20px'
      },
      '& .item': {
        alignItems: 'center',
        gap: 13,
        '& .order': {
          width: 30,
          minWidth: 30,
          fontSize: '20px'
        },
        '& p': {
          flex: 1,
          textAlign: 'start',
          fontSize: 16,
          lineHeight: '16px'
        }
      },
      '& .enroll': {
        marginTop: 15,
        '& a': {
          fontSize: '16px',
          padding: '5px 10px',
          '& svg': {
            fontSize: '25px'
          }
        }
      }
    }
  }
})
