import { useLocation } from 'react-router-dom'

function PurchaseDonePage () {
  const { result, sku, transactionId } = useLocation().state
  return <PurchaseDone result={result} sku={sku} transactionId={transactionId} />
}

function PurchaseDone ({ result, sku, transactionId }) {
  return (
    <div>
      <h1>Purchase Done</h1>
      <div>Coming soon</div>
      <pre>sku={sku} result={result} transactionId={transactionId}</pre>
    </div>
  )
}

export default PurchaseDonePage
