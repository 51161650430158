import { useMemo } from 'react'

import { isProd, useCurrentEpochAndUpdateWhenNeeded } from '../utils'

import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

export function useMonetizationData (data) {
  const { epoch, setEpochWhenUpdateNeeded } = useCurrentEpochAndUpdateWhenNeeded()

  return useMemo(() => {
    if (!data?.credits) {
      return { isLoading: true }
    }

    const currentSubscription = getSubscriptionAtEpoch(epoch, data.subscriptions)
    if (currentSubscription && currentSubscription.end / 1000 > epoch) {
      setEpochWhenUpdateNeeded(currentSubscription.end / 1000)
    }
    // Basically, the trial doesn’t really start until the first upload is done.
    // 1730415600 is the end of Oct-2024 so long-standing users aren’t cut off right away when we release monetization.
    const thirtyOneDaysInSeconds = 31 * 86400
    const freeTrialExpirationEpoch = Math.max(1730415600, (data.firstUploadEpoch ?? epoch) + thirtyOneDaysInSeconds)
    const ret = {
      creditsLeft: data.credits.left,
      currentSubscription,
      freeTrialExpirationEpoch,
      isSubscriber: Boolean(currentSubscription),
      subscriptions: data.subscriptions
    }
    // premium tier benefits until the free trial expires
    ret.hasPremiumAccess = ret.currentSubscription?.tier === 'premium' || epoch < freeTrialExpirationEpoch
    return ret
  }, [data, epoch, setEpochWhenUpdateNeeded])
}

function getSubscriptionAtEpoch (epoch, subscriptions) {
  const milliEpoch = epoch * 1000
  for (const sub of subscriptions) {
    if (milliEpoch >= sub.start && milliEpoch < sub.end) {
      return sub
    }
  }
  return null // not subscribed at epoch
}

// temporary
export function useIsBuyingEnabled () {
  // buying is enabled on test for everyone
  if (!isProd) {
    return true
  }
  // buying from within the native app's webview is disabled on prod because
  // the native apps do not yet support it
  if (isInMobileAppWebview()) {
    return false
  }
  // buying is enabled for everyone on web
  return true
}
